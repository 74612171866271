define('@fortawesome/pro-duotone-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faSpinner = {
    prefix: 'fad',
    iconName: 'spinner',
    icon: [512, 512, [], "f110", ["M369.1 75A48 48 0 1 1 437 142.9 48 48 0 1 1 369.1 75zM416 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM208 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM75 369.1A48 48 0 1 1 142.9 437 48 48 0 1 1 75 369.1zm294.2 0A48 48 0 1 1 437 437a48 48 0 1 1 -67.9-67.9z", "M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 256A48 48 0 1 0 0 256a48 48 0 1 0 96 0zM75 142.9A48 48 0 1 0 142.9 75 48 48 0 1 0 75 142.9z"]]
  };

  exports.faSpinner = faSpinner;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
