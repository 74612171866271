define("ember-template-compiler/lib/plugins/assert-splattribute-expression", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = assertSplattributeExpressions;
  function assertSplattributeExpressions(env) {
    var _a;
    var moduleName = (_a = env.meta) === null || _a === void 0 ? void 0 : _a.moduleName;
    return {
      name: 'assert-splattribute-expressions',
      visitor: {
        PathExpression(_ref) {
          var {
            original,
            loc
          } = _ref;
          if (original === '...attributes') {
            (true && !(false) && (0, _debug.assert)(errorMessage() + " " + (0, _calculateLocationDisplay.default)(moduleName, loc)));
          }
        }
      }
    };
  }
  function errorMessage() {
    return '`...attributes` can only be used in the element position e.g. `<div ...attributes />`. It cannot be used as a path.';
  }
});