define("ember-metrics/metrics-adapters/facebook-pixel", ["exports", "ember-metrics/-private/utils/object-transforms", "ember-metrics/-private/utils/remove-from-dom", "ember-metrics/metrics-adapters/base", "@ember/debug"], function (_exports, _objectTransforms, _removeFromDom, _base, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FacebookPixel extends _base.default {
    toStringExtension() {
      return 'FacebookPixel';
    }
    install() {
      const {
        id,
        dataProcessingOptions
      } = this.config;
      (false && !(id) && (0, _debug.assert)(`[ember-metrics] You must pass a valid \`id\` to the ${this.toString()} adapter`, id));
      if (window.fbq) {
        return;
      }
      this._injectScript();
      if (dataProcessingOptions) {
        const {
          method,
          country,
          state
        } = dataProcessingOptions;
        window.fbq('dataProcessingOptions', method, country, state);
      }
      window.fbq('init', id);

      // Leave this call due to Facebook API docs
      // https://developers.facebook.com/docs/facebook-pixel/api-reference#setup
      this.trackEvent({
        event: 'PageView'
      });
    }

    /* eslint-disable */
    // prettier-ignore
    _injectScript() {
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    }
    /* eslint-enable */

    trackEvent(options = {}) {
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        event
      } = compactedOptions;
      if (!event) {
        return;
      }
      delete compactedOptions.event;
      if (window.fbq) {
        window.fbq('track', event, compactedOptions);
      }
    }
    trackPage(options = {}) {
      if (window.fbq) {
        window.fbq('track', 'PageView', options);
      }
    }
    uninstall() {
      if (window.fbq) {
        (0, _removeFromDom.default)('script[src*="fbevents.js"]');
        delete window.fbq;
        delete window._fbq;
      }
    }
  }
  _exports.default = FacebookPixel;
});