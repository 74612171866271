define("ember-template-compiler/index", ["exports", "@ember/-internals/environment", "@ember/canary-features", "@glimmer/syntax", "ember/version", "require", "ember-template-compiler/lib/system/precompile", "ember-template-compiler/lib/system/compile", "ember-template-compiler/lib/system/compile-options", "ember-template-compiler/lib/plugins/index", "@glimmer/compiler", "ember-template-compiler/lib/system/bootstrap", "ember-template-compiler/lib/system/initializer"], function (_exports, _environment, _canaryFeatures, _GlimmerSyntax, _version, _require, _precompile, _compile, _compileOptions, _index, _compiler, _bootstrap, _initializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "RESOLUTION_MODE_TRANSFORMS", {
    enumerable: true,
    get: function () {
      return _index.RESOLUTION_MODE_TRANSFORMS;
    }
  });
  Object.defineProperty(_exports, "STRICT_MODE_TRANSFORMS", {
    enumerable: true,
    get: function () {
      return _index.STRICT_MODE_TRANSFORMS;
    }
  });
  Object.defineProperty(_exports, "VERSION", {
    enumerable: true,
    get: function () {
      return _version.default;
    }
  });
  _exports._GlimmerSyntax = _exports._Ember = void 0;
  Object.defineProperty(_exports, "_buildCompileOptions", {
    enumerable: true,
    get: function () {
      return _compileOptions.buildCompileOptions;
    }
  });
  Object.defineProperty(_exports, "_precompile", {
    enumerable: true,
    get: function () {
      return _compiler.precompile;
    }
  });
  Object.defineProperty(_exports, "_preprocess", {
    enumerable: true,
    get: function () {
      return _GlimmerSyntax.preprocess;
    }
  });
  Object.defineProperty(_exports, "_print", {
    enumerable: true,
    get: function () {
      return _GlimmerSyntax.print;
    }
  });
  Object.defineProperty(_exports, "_transformsFor", {
    enumerable: true,
    get: function () {
      return _compileOptions.transformsFor;
    }
  });
  Object.defineProperty(_exports, "compile", {
    enumerable: true,
    get: function () {
      return _compile.default;
    }
  });
  Object.defineProperty(_exports, "compileOptions", {
    enumerable: true,
    get: function () {
      return _compileOptions.default;
    }
  });
  Object.defineProperty(_exports, "precompile", {
    enumerable: true,
    get: function () {
      return _precompile.default;
    }
  });
  _exports._GlimmerSyntax = _GlimmerSyntax;
  var _Ember;
  _exports._Ember = _Ember;
  try {
    _exports._Ember = _Ember = (0, _require.default)("ember");
  } catch (e) {
    _exports._Ember = _Ember = {
      ENV: _environment.ENV,
      FEATURES: _canaryFeatures.FEATURES,
      VERSION: _version.default
    };
  }
});