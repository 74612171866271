define("@aboveproperty/dynamic-template/services/dynamic-template", ["exports", "@ember/service", "@ember/owner"], function (_exports, _service, _owner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Service used to override component templates
   */
  /* istanbul ignore next */
  let DynamicTemplateService = _exports.default = (_class = class DynamicTemplateService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    async overrideTemplates(customerID, options) {
      if (options?.overrideType === 'off') {
        return;
      }
      let marketingMedia = [];
      const config = (0, _owner.getOwner)(this).resolveRegistration('config:environment');
      if (config.environment !== 'test') {
        try {
          marketingMedia = await this.store.query('marketing-media', {
            customerID: customerID,
            industry: options.industry,
            mediaType: 'TEXT',
            mediaSubtype: 'HANDLEBARS'
          });
        } catch (e) {
          console.error('Failed to load marketing media', e);
        }
      }
      const customComponentTemplates = marketingMedia.filter(mm => mm.encapsulationType === 'COMPONENT').filter(mm => options?.overrideType === 'template' === mm.templateYN).reduce((obj, mm) => {
        obj[mm.reference] = {
          template: mm.richText || '',
          css: mm.css || ''
        };
        return obj;
      }, {});
      (0, _owner.getOwner)(this).register('config:dynamic-component-template', customComponentTemplates, {
        instantiate: false
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});