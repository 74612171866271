define("ember-template-compiler/lib/plugins/assert-against-named-outlets", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = assertAgainstNamedOutlets;
  /**
   @module ember
  */
  /**
    Prevents usage of named outlets, a legacy concept in Ember removed in 4.0.
  
    @private
    @class AssertAgainstNamedOutlets
  */
  function assertAgainstNamedOutlets(env) {
    var _a;
    var moduleName = (_a = env.meta) === null || _a === void 0 ? void 0 : _a.moduleName;
    return {
      name: 'assert-against-named-outlets',
      visitor: {
        MustacheStatement(node) {
          if (node.path.type === 'PathExpression' && node.path.original === 'outlet' && node.params[0]) {
            var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
            (true && !(false) && (0, _debug.assert)("Named outlets were removed in Ember 4.0. See https://deprecations.emberjs.com/v3.x#toc_route-render-template for guidance on alternative APIs for named outlet use cases. " + sourceInformation));
          }
        }
      }
    };
  }
});