define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faList = {
    prefix: 'far',
    iconName: 'list',
    icon: [512, 512, ["list-squares"], "f03a", "M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM184 72c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H184zm0 160c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H184zm0 160c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H184zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"]
  };
  var faCaretUp = {
    prefix: 'far',
    iconName: 'caret-up',
    icon: [320, 512, [], "f0d8", "M160 182.6L70.6 272H249.4L160 182.6zm-22.6-45.3c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128z"]
  };
  var faCaretDown = {
    prefix: 'far',
    iconName: 'caret-down',
    icon: [320, 512, [], "f0d7", "M160 329.4L249.4 240 70.6 240 160 329.4zm22.6 45.3c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"]
  };
  var faHouse = {
    prefix: 'far',
    iconName: 'house',
    icon: [576, 512, [127968, 63498, 63500, "home", "home-alt", "home-lg-alt"], "f015", "M272.5 5.7c9-7.6 22.1-7.6 31.1 0l264 224c10.1 8.6 11.4 23.7 2.8 33.8s-23.7 11.3-33.8 2.8L512 245.5V432c0 44.2-35.8 80-80 80H144c-44.2 0-80-35.8-80-80V245.5L39.5 266.3c-10.1 8.6-25.3 7.3-33.8-2.8s-7.3-25.3 2.8-33.8l264-224zM288 55.5L112 204.8V432c0 17.7 14.3 32 32 32h48V312c0-22.1 17.9-40 40-40H344c22.1 0 40 17.9 40 40V464h48c17.7 0 32-14.3 32-32V204.8L288 55.5zM240 464h96V320H240V464z"]
  };
  var faBed = {
    prefix: 'far',
    iconName: 'bed',
    icon: [640, 512, [128716], "f236", "M48 56c0-13.3-10.7-24-24-24S0 42.7 0 56V360v96c0 13.3 10.7 24 24 24s24-10.7 24-24V384H312 592v80c0 13.3 10.7 24 24 24s24-10.7 24-24V360 248c0-66.3-53.7-120-120-120H344c-30.9 0-56 25.1-56 56V336H48V56zM592 248v88H336V184c0-4.4 3.6-8 8-8H520c39.8 0 72 32.2 72 72zM128 216a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zm128 0A88 88 0 1 0 80 216a88 88 0 1 0 176 0z"]
  };
  var faLocationDot = {
    prefix: 'far',
    iconName: 'location-dot',
    icon: [384, 512, ["map-marker-alt"], "f3c5", "M336 192c0-79.5-64.5-144-144-144S48 112.5 48 192c0 12.4 4.5 31.6 15.3 57.2c10.5 24.8 25.4 52.2 42.5 79.9c28.5 46.2 61.5 90.8 86.2 122.6c24.8-31.8 57.8-76.4 86.2-122.6c17.1-27.7 32-55.1 42.5-79.9C331.5 223.6 336 204.4 336 192zm48 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-160 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-112 0a80 80 0 1 1 160 0 80 80 0 1 1 -160 0z"]
  };
  var faCalendarDay = {
    prefix: 'far',
    iconName: 'calendar-day',
    icon: [448, 512, [], "f783", "M128 0c13.3 0 24 10.7 24 24V64H296V24c0-13.3 10.7-24 24-24s24 10.7 24 24V64h40c35.3 0 64 28.7 64 64v16 48V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 144 128C0 92.7 28.7 64 64 64h40V24c0-13.3 10.7-24 24-24zM400 192H48V448c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V192zM112 256h96c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16H112c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16z"]
  };

  exports.faBed = faBed;
  exports.faCalendarDay = faCalendarDay;
  exports.faCaretDown = faCaretDown;
  exports.faCaretUp = faCaretUp;
  exports.faHouse = faHouse;
  exports.faList = faList;
  exports.faLocationDot = faLocationDot;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
