define("ember-template-compiler/lib/system/bootstrap", ["exports", "ember-template-compiler/lib/system/compile"], function (_exports, _compile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  @module ember
  */

  /**
    Find templates stored in the head tag as script tags and make them available
    to `Ember.CoreView` in the global `Ember.TEMPLATES` object.
  
    Script tags with `text/x-handlebars` will be compiled
    with Ember's template compiler and are suitable for use as a view's template.
  
    @private
    @method bootstrap
    @for Ember.HTMLBars
    @static
    @param ctx
  */
  function bootstrap(_ref) {
    var {
      context,
      hasTemplate,
      setTemplate
    } = _ref;
    if (!context) {
      context = document;
    }
    var selector = 'script[type="text/x-handlebars"]';
    var elements = context.querySelectorAll(selector);
    for (var script of elements) {
      // Get the name of the script
      // First look for data-template-name attribute, then fall back to its
      // id if no name is found.
      var templateName = script.getAttribute('data-template-name') || script.getAttribute('id') || 'application';
      var template = void 0;
      template = (0, _compile.default)(script.innerHTML, {
        moduleName: templateName
      });
      // Check if template of same name already exists.
      if (hasTemplate(templateName)) {
        throw new Error("Template named \"" + templateName + "\" already exists.");
      }
      // For templates which have a name, we save them and then remove them from the DOM.
      setTemplate(templateName, template);
      // Remove script tag from DOM.
      script.parentNode.removeChild(script);
    }
  }
  var _default = bootstrap;
  _exports.default = _default;
});