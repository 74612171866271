define("ember-template-compiler/lib/plugins/assert-against-attrs", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = assertAgainstAttrs;
  /**
   @module ember
  */
  /**
    A Glimmer2 AST transformation that asserts against
  
    ```handlebars
    {{attrs.foo.bar}}
    ```
  
    ...as well as `{{#if attrs.foo}}`, `{{deeply (nested attrs.foobar.baz)}}`.
  
    @private
    @class AssertAgainstAttrs
  */
  function assertAgainstAttrs(env) {
    var _a;
    var {
      builders: b
    } = env.syntax;
    var moduleName = (_a = env.meta) === null || _a === void 0 ? void 0 : _a.moduleName;
    var stack = [[]];
    function updateBlockParamsStack(blockParams) {
      var parent = stack[stack.length - 1];
      (true && !(parent) && (0, _debug.assert)('has parent', parent));
      stack.push(parent.concat(blockParams));
    }
    return {
      name: 'assert-against-attrs',
      visitor: {
        Program: {
          enter(node) {
            updateBlockParamsStack(node.blockParams);
          },
          exit() {
            stack.pop();
          }
        },
        ElementNode: {
          enter(node) {
            updateBlockParamsStack(node.blockParams);
          },
          exit() {
            stack.pop();
          }
        },
        PathExpression(node) {
          if (isAttrs(node, stack[stack.length - 1])) {
            var path = b.path(node.original.substring(6));
            (true && !(node.this !== false) && (0, _debug.assert)("Using {{attrs}} to reference named arguments is not supported. {{attrs." + path.original + "}} should be updated to {{@" + path.original + "}}. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), node.this !== false));
          }
        }
      }
    };
  }
  function isAttrs(node, symbols) {
    var name = node.parts[0];
    if (name && symbols.indexOf(name) !== -1) {
      return false;
    }
    if (name === 'attrs') {
      if (node.this === true) {
        node.parts.shift();
        node.original = node.original.slice(5);
      }
      return true;
    }
    return false;
  }
});