define("ember-leaflet/services/ember-leaflet", ["exports", "@ember/service", "@ember/debug"], function (_exports, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class EmberLeafletService extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "components", []);
    }
    registerComponent(name, options = {}) {
      let as = options.as || name;
      (false && !(this.components.find(c => c.name === name || c.as === as) === undefined) && (0, _debug.assert)(`Tried to register component \`${name}\` as \`${as}\`, but it was already registered. Try to register a different component or register it under a different name.`, this.components.find(c => c.name === name || c.as === as) === undefined));
      this.components.push({
        ...options,
        name,
        as
      });
    }
  }
  _exports.default = EmberLeafletService;
});