define("ember-template-compiler/lib/plugins/transform-wrap-mount-and-outlet", ["exports", "ember-template-compiler/lib/plugins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformWrapMountAndOutlet;
  /**
   @module ember
  */
  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
    {{mount "engine" model=this.model}}
    ```
  
    with
  
    ```handlebars
    {{component (-mount "engine" model=this.model)}}
    ```
  
    and
  
    ```handlebars
    {{outlet}}
    ```
  
    with
  
    ```handlebars
    {{component (-outlet)}}
    ```
  
    @private
    @class TransformHasBlockSyntax
  */
  function transformWrapMountAndOutlet(env) {
    var {
      builders: b
    } = env.syntax;
    var {
      hasLocal,
      node
    } = (0, _utils.trackLocals)();
    return {
      name: 'transform-wrap-mount-and-outlet',
      visitor: {
        Program: node,
        ElementNode: node,
        MustacheStatement(node) {
          if ((0, _utils.isPath)(node.path) && (node.path.original === 'mount' || node.path.original === 'outlet') && !hasLocal(node.path.original)) {
            var subexpression = b.sexpr(b.path("-" + node.path.original), node.params, node.hash, node.loc);
            return b.mustache(b.path('component'), [subexpression], b.hash(), undefined, node.loc);
          }
        }
      }
    };
  }
});