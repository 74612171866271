define("ember-template-compiler/lib/plugins/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPath = isPath;
  _exports.isStringLiteral = isStringLiteral;
  _exports.isSubExpression = isSubExpression;
  _exports.trackLocals = trackLocals;
  function isPath(node) {
    return node.type === 'PathExpression';
  }
  function isSubExpression(node) {
    return node.type === 'SubExpression';
  }
  function isStringLiteral(node) {
    return node.type === 'StringLiteral';
  }
  function trackLocals() {
    var locals = new Map();
    var node = {
      enter(node) {
        for (var param of node.blockParams) {
          var value = locals.get(param) || 0;
          locals.set(param, value + 1);
        }
      },
      exit(node) {
        for (var param of node.blockParams) {
          var value = locals.get(param) - 1;
          if (value === 0) {
            locals.delete(param);
          } else {
            locals.set(param, value);
          }
        }
      }
    };
    return {
      hasLocal: key => locals.has(key),
      node
    };
  }
});