define("@glimmer/compiler", ["exports", "@glimmer/syntax", "@glimmer/util"], function (_exports, _syntax, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WireFormatDebugger = _exports.ProgramSymbols = _exports.NEWLINE = void 0;
  _exports.buildStatement = buildStatement;
  _exports.buildStatements = buildStatements;
  _exports.c = c;
  _exports.defaultId = void 0;
  _exports.precompile = precompile;
  _exports.precompileJSON = precompileJSON;
  _exports.s = s;
  _exports.unicode = unicode;
  class Template extends (0, _syntax.node)('Template').fields() {}
  class InElement extends (0, _syntax.node)('InElement').fields() {}
  class Not extends (0, _syntax.node)('Not').fields() {}
  class If extends (0, _syntax.node)('If').fields() {}
  class IfInline extends (0, _syntax.node)('IfInline').fields() {}
  class Each extends (0, _syntax.node)('Each').fields() {}
  class With extends (0, _syntax.node)('With').fields() {}
  class Let extends (0, _syntax.node)('Let').fields() {}
  class WithDynamicVars extends (0, _syntax.node)('WithDynamicVars').fields() {}
  class GetDynamicVar extends (0, _syntax.node)('GetDynamicVar').fields() {}
  class Log extends (0, _syntax.node)('Log').fields() {}
  class InvokeComponent extends (0, _syntax.node)('InvokeComponent').fields() {}
  class NamedBlocks extends (0, _syntax.node)('NamedBlocks').fields() {}
  class NamedBlock extends (0, _syntax.node)('NamedBlock').fields() {}
  class EndBlock extends (0, _syntax.node)('EndBlock').fields() {}
  class AppendTrustedHTML extends (0, _syntax.node)('AppendTrustedHTML').fields() {}
  class AppendTextNode extends (0, _syntax.node)('AppendTextNode').fields() {}
  class AppendComment extends (0, _syntax.node)('AppendComment').fields() {}
  class Component extends (0, _syntax.node)('Component').fields() {}
  class StaticAttr extends (0, _syntax.node)('StaticAttr').fields() {}
  class DynamicAttr extends (0, _syntax.node)('DynamicAttr').fields() {}
  class SimpleElement extends (0, _syntax.node)('SimpleElement').fields() {}
  class ElementParameters extends (0, _syntax.node)('ElementParameters').fields() {}
  class Yield extends (0, _syntax.node)('Yield').fields() {}
  class Debugger extends (0, _syntax.node)('Debugger').fields() {}
  class CallExpression extends (0, _syntax.node)('CallExpression').fields() {}
  class DeprecatedCallExpression extends (0, _syntax.node)('DeprecatedCallExpression').fields() {}
  class Modifier extends (0, _syntax.node)('Modifier').fields() {}
  class InvokeBlock extends (0, _syntax.node)('InvokeBlock').fields() {}
  class SplatAttr extends (0, _syntax.node)('SplatAttr').fields() {}
  class PathExpression extends (0, _syntax.node)('PathExpression').fields() {}
  class GetWithResolver extends (0, _syntax.node)('GetWithResolver').fields() {}
  class GetSymbol extends (0, _syntax.node)('GetSymbol').fields() {}
  class GetFreeWithContext extends (0, _syntax.node)('GetFreeWithContext').fields() {}
  /** strict mode */

  class GetFree extends (0, _syntax.node)('GetFree').fields() {}
  class Missing extends (0, _syntax.node)('Missing').fields() {}
  class InterpolateExpression extends (0, _syntax.node)('InterpolateExpression').fields() {}
  class HasBlock extends (0, _syntax.node)('HasBlock').fields() {}
  class HasBlockParams extends (0, _syntax.node)('HasBlockParams').fields() {}
  class Curry extends (0, _syntax.node)('Curry').fields() {}
  class Positional extends (0, _syntax.node)('Positional').fields() {}
  class NamedArguments extends (0, _syntax.node)('NamedArguments').fields() {}
  class NamedArgument extends (0, _syntax.node)('NamedArgument').fields() {}
  class Args extends (0, _syntax.node)('Args').fields() {}
  class Tail extends (0, _syntax.node)('Tail').fields() {}
  class PresentList {
    constructor(list) {
      this.list = list;
    }
    toArray() {
      return this.list;
    }
    map(callback) {
      var result = (0, _util.mapPresent)(this.list, callback);
      return new PresentList(result);
    }
    filter(predicate) {
      var out = [];
      for (var item of this.list) {
        if (predicate(item)) {
          out.push(item);
        }
      }
      return OptionalList(out);
    }
    toPresentArray() {
      return this.list;
    }
    into(_ref) {
      var {
        ifPresent
      } = _ref;
      return ifPresent(this);
    }
  }
  class EmptyList {
    constructor() {
      this.list = [];
    }
    map(_callback) {
      return new EmptyList();
    }
    filter(_predicate) {
      return new EmptyList();
    }
    toArray() {
      return this.list;
    }
    toPresentArray() {
      return null;
    }
    into(_ref2) {
      var {
        ifEmpty
      } = _ref2;
      return ifEmpty();
    }
  } // export type OptionalList<T> = PresentList<T> | EmptyList<T>;

  function OptionalList(value) {
    if ((0, _util.isPresent)(value)) {
      return new PresentList(value);
    } else {
      return new EmptyList();
    }
  }
  class ResultImpl {
    static all() {
      var out = [];
      for (var _len = arguments.length, results = new Array(_len), _key = 0; _key < _len; _key++) {
        results[_key] = arguments[_key];
      }
      for (var result of results) {
        if (result.isErr) {
          return result.cast();
        } else {
          out.push(result.value);
        }
      }
      return Ok(out);
    }
  }
  var Result = ResultImpl;
  class OkImpl extends ResultImpl {
    constructor(value) {
      super();
      this.value = value;
      this.isOk = true;
      this.isErr = false;
    }
    expect(_message) {
      return this.value;
    }
    ifOk(callback) {
      callback(this.value);
      return this;
    }
    andThen(callback) {
      return callback(this.value);
    }
    mapOk(callback) {
      return Ok(callback(this.value));
    }
    ifErr(_callback) {
      return this;
    }
    mapErr(_callback) {
      return this;
    }
  }
  class ErrImpl extends ResultImpl {
    constructor(reason) {
      super();
      this.reason = reason;
      this.isOk = false;
      this.isErr = true;
    }
    expect(message) {
      throw new Error(message || 'expected an Ok, got Err');
    }
    andThen(_callback) {
      return this.cast();
    }
    mapOk(_callback) {
      return this.cast();
    }
    ifOk(_callback) {
      return this;
    }
    mapErr(callback) {
      return Err(callback(this.reason));
    }
    ifErr(callback) {
      callback(this.reason);
      return this;
    }
    cast() {
      return this;
    }
  }
  function Ok(value) {
    return new OkImpl(value);
  }
  function Err(reason) {
    return new ErrImpl(reason);
  }
  class ResultArray {
    constructor(items) {
      if (items === void 0) {
        items = [];
      }
      this.items = items;
    }
    add(item) {
      this.items.push(item);
    }
    toArray() {
      var err = this.items.filter(item => item instanceof ErrImpl)[0];
      if (err !== undefined) {
        return err.cast();
      } else {
        return Ok(this.items.map(item => item.value));
      }
    }
    toOptionalList() {
      return this.toArray().mapOk(arr => OptionalList(arr));
    }
  }
  class KeywordImpl {
    constructor(keyword, type, delegate) {
      this.keyword = keyword;
      this.delegate = delegate;
      var nodes = new Set();
      for (var nodeType of KEYWORD_NODES[type]) {
        nodes.add(nodeType);
      }
      this.types = nodes;
    }
    match(node$$1) {
      if (!this.types.has(node$$1.type)) {
        return false;
      }
      var path = getCalleeExpression(node$$1);
      if (path !== null && path.type === 'Path' && path.ref.type === 'Free') {
        if (path.tail.length > 0) {
          if (path.ref.resolution.serialize() === 'Loose') {
            // cannot be a keyword reference, keywords do not allow paths (must be
            // relying on implicit this fallback)
            return false;
          }
        }
        return path.ref.name === this.keyword;
      } else {
        return false;
      }
    }
    translate(node$$1, state) {
      if (this.match(node$$1)) {
        var path = getCalleeExpression(node$$1);
        if (path !== null && path.type === 'Path' && path.tail.length > 0) {
          return Err((0, _syntax.generateSyntaxError)("The `" + this.keyword + "` keyword was used incorrectly. It was used as `" + path.loc.asString() + "`, but it cannot be used with additional path segments. \n\nError caused by", node$$1.loc));
        }
        var param = this.delegate.assert(node$$1, state);
        return param.andThen(param => this.delegate.translate({
          node: node$$1,
          state
        }, param));
      } else {
        return null;
      }
    }
  }
  var KEYWORD_NODES = {
    Call: ['Call'],
    Block: ['InvokeBlock'],
    Append: ['AppendContent'],
    Modifier: ['ElementModifier']
  };
  function keyword(keyword, type, delegate) {
    return new KeywordImpl(keyword, type, delegate);
  }
  function getCalleeExpression(node$$1) {
    switch (node$$1.type) {
      // This covers the inside of attributes and expressions, as well as the callee
      // of call nodes
      case 'Path':
        return node$$1;
      case 'AppendContent':
        return getCalleeExpression(node$$1.value);
      case 'Call':
      case 'InvokeBlock':
      case 'ElementModifier':
        return node$$1.callee;
      default:
        return null;
    }
  }
  class Keywords {
    constructor(type) {
      this._keywords = [];
      this._type = type;
    }
    kw(name, delegate) {
      this._keywords.push(keyword(name, this._type, delegate));
      return this;
    }
    translate(node$$1, state) {
      for (var _keyword of this._keywords) {
        var result = _keyword.translate(node$$1, state);
        if (result !== null) {
          return result;
        }
      }
      var path = getCalleeExpression(node$$1);
      if (path && path.type === 'Path' && path.ref.type === 'Free' && (0, _syntax.isKeyword)(path.ref.name)) {
        var {
          name
        } = path.ref;
        var usedType = this._type;
        var validTypes = _syntax.KEYWORDS_TYPES[name];
        if (validTypes.indexOf(usedType) === -1) {
          return Err((0, _syntax.generateSyntaxError)("The `" + name + "` keyword was used incorrectly. It was used as " + typesToReadableName[usedType] + ", but its valid usages are:\n\n" + generateTypesMessage(name, validTypes) + "\n\nError caused by", node$$1.loc));
        }
      }
      return null;
    }
  }
  var typesToReadableName = {
    Append: 'an append statement',
    Block: 'a block statement',
    Call: 'a call expression',
    Modifier: 'a modifier'
  };
  function generateTypesMessage(name, types) {
    return types.map(type => {
      switch (type) {
        case 'Append':
          return "- As an append statement, as in: {{" + name + "}}";
        case 'Block':
          return "- As a block statement, as in: {{#" + name + "}}{{/" + name + "}}";
        case 'Call':
          return "- As an expression, as in: (" + name + ")";
        case 'Modifier':
          return "- As a modifier, as in: <div {{" + name + "}}></div>";
        default:
          return (0, _util.exhausted)(type);
      }
    }).join('\n\n');
  }
  /**
   * This function builds keyword definitions for a particular type of AST node (`KeywordType`).
   *
   * You can build keyword definitions for:
   *
   * - `Expr`: A `SubExpression` or `PathExpression`
   * - `Block`: A `BlockStatement`
   *   - A `BlockStatement` is a keyword candidate if its head is a
   *     `PathExpression`
   * - `Append`: An `AppendStatement`
   *
   * A node is a keyword candidate if:
   *
   * - A `PathExpression` is a keyword candidate if it has no tail, and its
   *   head expression is a `LocalVarHead` or `FreeVarHead` whose name is
   *   the keyword's name.
   * - A `SubExpression`, `AppendStatement`, or `BlockStatement` is a keyword
   *   candidate if its head is a keyword candidate.
   *
   * The keyword infrastructure guarantees that:
   *
   * - If a node is not a keyword candidate, it is never passed to any keyword's
   *   `assert` method.
   * - If a node is not the `KeywordType` for a particular keyword, it will not
   *   be passed to the keyword's `assert` method.
   *
   * `Expr` keywords are used in expression positions and should return HIR
   * expressions. `Block` and `Append` keywords are used in statement
   * positions and should return HIR statements.
   *
   * A keyword definition has two parts:
   *
   * - `match`, which determines whether an AST node matches the keyword, and can
   *   optionally return some information extracted from the AST node.
   * - `translate`, which takes a matching AST node as well as the extracted
   *   information and returns an appropriate HIR instruction.
   *
   * # Example
   *
   * This keyword:
   *
   * - turns `(hello)` into `"hello"`
   *   - as long as `hello` is not in scope
   * - makes it an error to pass any arguments (such as `(hello world)`)
   *
   * ```ts
   * keywords('SubExpr').kw('hello', {
   *   assert(node: ExprKeywordNode): Result<void> | false {
   *     // we don't want to transform `hello` as a `PathExpression`
   *     if (node.type !== 'SubExpression') {
   *       return false;
   *     }
   *
   *     // node.head would be `LocalVarHead` if `hello` was in scope
   *     if (node.head.type !== 'FreeVarHead') {
   *       return false;
   *     }
   *
   *     if (node.params.length || node.hash) {
   *       return Err(generateSyntaxError(`(hello) does not take any arguments`), node.loc);
   *     } else {
   *       return Ok();
   *     }
   *   },
   *
   *   translate(node: ASTv2.SubExpression): hir.Expression {
   *     return ASTv2.builders.literal("hello", node.loc)
   *   }
   * })
   * ```
   *
   * The keyword infrastructure checks to make sure that the node is the right
   * type before calling `assert`, so you only need to consider `SubExpression`
   * and `PathExpression` here. It also checks to make sure that the node passed
   * to `assert` has the keyword name in the right place.
   *
   * Note the important difference between returning `false` from `assert`,
   * which just means that the node didn't match, and returning `Err`, which
   * means that the node matched, but there was a keyword-specific syntax
   * error.
   */

  function keywords(type) {
    return new Keywords(type);
  }
  function hasPath(node$$1) {
    return node$$1.callee.type === 'Path';
  }
  function isHelperInvocation(node$$1) {
    if (!hasPath(node$$1)) {
      return false;
    }
    return !node$$1.args.isEmpty();
  }
  function isSimplePath(path) {
    if (path.type === 'Path') {
      var {
        ref: head,
        tail: parts
      } = path;
      return head.type === 'Free' && head.resolution !== _syntax.ASTv2.STRICT_RESOLUTION && parts.length === 0;
    } else {
      return false;
    }
  }
  function isStrictHelper(expr) {
    if (expr.callee.type !== 'Path') {
      return true;
    }
    if (expr.callee.ref.type !== 'Free') {
      return true;
    }
    return expr.callee.ref.resolution === _syntax.ASTv2.STRICT_RESOLUTION;
  }
  function assertIsValidModifier(helper) {
    if (isStrictHelper(helper) || isSimplePath(helper.callee)) {
      return;
    }
    throw (0, _syntax.generateSyntaxError)("`" + printPath(helper.callee) + "` is not a valid name for a modifier", helper.loc);
  }
  function printPath(path) {
    switch (path.type) {
      case 'Literal':
        return JSON.stringify(path.value);
      case 'Path':
        {
          var printedPath = [printPathHead(path.ref)];
          printedPath.push(...path.tail.map(t => t.chars));
          return printedPath.join('.');
        }
      case 'Call':
        return "(" + printPath(path.callee) + " ...)";
      case 'DeprecatedCall':
        return "" + path.callee.name;
      case 'Interpolate':
        throw (0, _util.unreachable)('a concat statement cannot appear as the head of an expression');
    }
  }
  function printPathHead(head) {
    switch (head.type) {
      case 'Arg':
        return head.name.chars;
      case 'Free':
      case 'Local':
        return head.name;
      case 'This':
        return 'this';
    }
  }
  class NormalizeExpressions {
    visit(node$$1, state) {
      switch (node$$1.type) {
        case 'Literal':
          return Ok(this.Literal(node$$1));
        case 'Interpolate':
          return this.Interpolate(node$$1, state);
        case 'Path':
          return this.PathExpression(node$$1);
        case 'Call':
          var translated = CALL_KEYWORDS.translate(node$$1, state);
          if (translated !== null) {
            return translated;
          }
          return this.CallExpression(node$$1, state);
        case 'DeprecatedCall':
          return this.DeprecaedCallExpression(node$$1, state);
      }
    }
    visitList(nodes, state) {
      return new ResultArray(nodes.map(e => VISIT_EXPRS.visit(e, state))).toOptionalList();
    }
    /**
     * Normalize paths into `hir.Path` or a `hir.Expr` that corresponds to the ref.
     *
     * TODO since keywords don't support tails anyway, distinguish PathExpression from
     * VariableReference in ASTv2.
     */

    PathExpression(path) {
      var ref = this.VariableReference(path.ref);
      var {
        tail
      } = path;
      if ((0, _util.isPresent)(tail)) {
        var tailLoc = tail[0].loc.extend(tail[tail.length - 1].loc);
        return Ok(new PathExpression({
          loc: path.loc,
          head: ref,
          tail: new Tail({
            loc: tailLoc,
            members: tail
          })
        }));
      } else {
        return Ok(ref);
      }
    }
    VariableReference(ref) {
      return ref;
    }
    Literal(literal) {
      return literal;
    }
    Interpolate(expr, state) {
      var parts = expr.parts.map(convertPathToCallIfKeyword);
      return VISIT_EXPRS.visitList(parts, state).mapOk(parts => new InterpolateExpression({
        loc: expr.loc,
        parts: parts
      }));
    }
    CallExpression(expr, state) {
      if (!hasPath(expr)) {
        throw new Error("unimplemented subexpression at the head of a subexpression");
      } else {
        return Result.all(VISIT_EXPRS.visit(expr.callee, state), VISIT_EXPRS.Args(expr.args, state)).mapOk(_ref3 => {
          var [callee, args] = _ref3;
          return new CallExpression({
            loc: expr.loc,
            callee,
            args
          });
        });
      }
    }
    DeprecaedCallExpression(_ref4, _state) {
      var {
        arg,
        callee,
        loc
      } = _ref4;
      return Ok(new DeprecatedCallExpression({
        loc,
        arg,
        callee
      }));
    }
    Args(_ref5, state) {
      var {
        positional,
        named,
        loc
      } = _ref5;
      return Result.all(this.Positional(positional, state), this.NamedArguments(named, state)).mapOk(_ref6 => {
        var [positional, named] = _ref6;
        return new Args({
          loc,
          positional,
          named
        });
      });
    }
    Positional(positional, state) {
      return VISIT_EXPRS.visitList(positional.exprs, state).mapOk(list => new Positional({
        loc: positional.loc,
        list
      }));
    }
    NamedArguments(named, state) {
      var pairs = named.entries.map(arg => {
        var value = convertPathToCallIfKeyword(arg.value);
        return VISIT_EXPRS.visit(value, state).mapOk(value => new NamedArgument({
          loc: arg.loc,
          key: arg.name,
          value
        }));
      });
      return new ResultArray(pairs).toOptionalList().mapOk(pairs => new NamedArguments({
        loc: named.loc,
        entries: pairs
      }));
    }
  }
  function convertPathToCallIfKeyword(path) {
    if (path.type === 'Path' && path.ref.type === 'Free' && path.ref.name in _syntax.KEYWORDS_TYPES) {
      return new _syntax.ASTv2.CallExpression({
        callee: path,
        args: _syntax.ASTv2.Args.empty(path.loc),
        loc: path.loc
      });
    }
    return path;
  }
  var VISIT_EXPRS = new NormalizeExpressions();
  var CurriedTypeToReadableType = {
    [0
    /* Component */]: 'component',
    [1
    /* Helper */]: 'helper',
    [2
    /* Modifier */]: 'modifier'
  };
  function assertCurryKeyword(curriedType) {
    return (node$$1, state) => {
      var readableType = CurriedTypeToReadableType[curriedType];
      var stringsAllowed = curriedType === 0
      /* Component */;

      var {
        args
      } = node$$1;
      var definition = args.nth(0);
      if (definition === null) {
        return Err((0, _syntax.generateSyntaxError)("(" + readableType + ") requires a " + readableType + " definition or identifier as its first positional parameter, did not receive any parameters.", args.loc));
      }
      if (definition.type === 'Literal') {
        if (stringsAllowed && state.isStrict) {
          return Err((0, _syntax.generateSyntaxError)("(" + readableType + ") cannot resolve string values in strict mode templates", node$$1.loc));
        } else if (!stringsAllowed) {
          return Err((0, _syntax.generateSyntaxError)("(" + readableType + ") cannot resolve string values, you must pass a " + readableType + " definition directly", node$$1.loc));
        }
      }
      args = new _syntax.ASTv2.Args({
        positional: new _syntax.ASTv2.PositionalArguments({
          exprs: args.positional.exprs.slice(1),
          loc: args.positional.loc
        }),
        named: args.named,
        loc: args.loc
      });
      return Ok({
        definition,
        args
      });
    };
  }
  function translateCurryKeyword(curriedType) {
    return (_ref7, _ref8) => {
      var {
        node: node$$1,
        state
      } = _ref7;
      var {
        definition,
        args
      } = _ref8;
      var definitionResult = VISIT_EXPRS.visit(definition, state);
      var argsResult = VISIT_EXPRS.Args(args, state);
      return Result.all(definitionResult, argsResult).mapOk(_ref9 => {
        var [definition, args] = _ref9;
        return new Curry({
          loc: node$$1.loc,
          curriedType,
          definition,
          args
        });
      });
    };
  }
  function curryKeyword(curriedType) {
    return {
      assert: assertCurryKeyword(curriedType),
      translate: translateCurryKeyword(curriedType)
    };
  }
  function assertGetDynamicVarKeyword(node$$1) {
    var call = node$$1.type === 'AppendContent' ? node$$1.value : node$$1;
    var named = call.type === 'Call' ? call.args.named : null;
    var positionals = call.type === 'Call' ? call.args.positional : null;
    if (named && !named.isEmpty()) {
      return Err((0, _syntax.generateSyntaxError)("(-get-dynamic-vars) does not take any named arguments", node$$1.loc));
    }
    var varName = positionals === null || positionals === void 0 ? void 0 : positionals.nth(0);
    if (!varName) {
      return Err((0, _syntax.generateSyntaxError)("(-get-dynamic-vars) requires a var name to get", node$$1.loc));
    }
    if (positionals && positionals.size > 1) {
      return Err((0, _syntax.generateSyntaxError)("(-get-dynamic-vars) only receives one positional arg", node$$1.loc));
    }
    return Ok(varName);
  }
  function translateGetDynamicVarKeyword(_ref10, name) {
    var {
      node: node$$1,
      state
    } = _ref10;
    return VISIT_EXPRS.visit(name, state).mapOk(name => new GetDynamicVar({
      name,
      loc: node$$1.loc
    }));
  }
  var getDynamicVarKeyword = {
    assert: assertGetDynamicVarKeyword,
    translate: translateGetDynamicVarKeyword
  };
  function assertHasBlockKeyword(type) {
    return node$$1 => {
      var call = node$$1.type === 'AppendContent' ? node$$1.value : node$$1;
      var named = call.type === 'Call' ? call.args.named : null;
      var positionals = call.type === 'Call' ? call.args.positional : null;
      if (named && !named.isEmpty()) {
        return Err((0, _syntax.generateSyntaxError)("(" + type + ") does not take any named arguments", call.loc));
      }
      if (!positionals || positionals.isEmpty()) {
        return Ok(_syntax.SourceSlice.synthetic('default'));
      } else if (positionals.exprs.length === 1) {
        var positional = positionals.exprs[0];
        if (_syntax.ASTv2.isLiteral(positional, 'string')) {
          return Ok(positional.toSlice());
        } else {
          return Err((0, _syntax.generateSyntaxError)("(" + type + ") can only receive a string literal as its first argument", call.loc));
        }
      } else {
        return Err((0, _syntax.generateSyntaxError)("(" + type + ") only takes a single positional argument", call.loc));
      }
    };
  }
  function translateHasBlockKeyword(type) {
    return (_ref11, target) => {
      var {
        node: node$$1,
        state: {
          scope
        }
      } = _ref11;
      var block = type === 'has-block' ? new HasBlock({
        loc: node$$1.loc,
        target,
        symbol: scope.allocateBlock(target.chars)
      }) : new HasBlockParams({
        loc: node$$1.loc,
        target,
        symbol: scope.allocateBlock(target.chars)
      });
      return Ok(block);
    };
  }
  function hasBlockKeyword(type) {
    return {
      assert: assertHasBlockKeyword(type),
      translate: translateHasBlockKeyword(type)
    };
  }
  function assertIfUnlessInlineKeyword(type) {
    return originalNode => {
      var _a;
      var inverted = type === 'unless';
      var node$$1 = originalNode.type === 'AppendContent' ? originalNode.value : originalNode;
      var named = node$$1.type === 'Call' ? node$$1.args.named : null;
      var positional = node$$1.type === 'Call' ? node$$1.args.positional : null;
      if (named && !named.isEmpty()) {
        return Err((0, _syntax.generateSyntaxError)("(" + type + ") cannot receive named parameters, received " + named.entries.map(e => e.name.chars).join(', '), originalNode.loc));
      }
      var condition = positional === null || positional === void 0 ? void 0 : positional.nth(0);
      if (!positional || !condition) {
        return Err((0, _syntax.generateSyntaxError)("When used inline, (" + type + ") requires at least two parameters 1. the condition that determines the state of the (" + type + "), and 2. the value to return if the condition is " + (inverted ? 'false' : 'true') + ". Did not receive any parameters", originalNode.loc));
      }
      var truthy = positional.nth(1);
      var falsy = positional.nth(2);
      if (truthy === null) {
        return Err((0, _syntax.generateSyntaxError)("When used inline, (" + type + ") requires at least two parameters 1. the condition that determines the state of the (" + type + "), and 2. the value to return if the condition is " + (inverted ? 'false' : 'true') + ". Received only one parameter, the condition", originalNode.loc));
      }
      if (positional.size > 3) {
        return Err((0, _syntax.generateSyntaxError)("When used inline, (" + type + ") can receive a maximum of three positional parameters 1. the condition that determines the state of the (" + type + "), 2. the value to return if the condition is " + (inverted ? 'false' : 'true') + ", and 3. the value to return if the condition is " + (inverted ? 'true' : 'false') + ". Received " + ((_a = positional === null || positional === void 0 ? void 0 : positional.size) !== null && _a !== void 0 ? _a : 0) + " parameters", originalNode.loc));
      }
      return Ok({
        condition,
        truthy,
        falsy
      });
    };
  }
  function translateIfUnlessInlineKeyword(type) {
    var inverted = type === 'unless';
    return (_ref12, _ref13) => {
      var {
        node: node$$1,
        state
      } = _ref12;
      var {
        condition,
        truthy,
        falsy
      } = _ref13;
      var conditionResult = VISIT_EXPRS.visit(condition, state);
      var truthyResult = VISIT_EXPRS.visit(truthy, state);
      var falsyResult = falsy ? VISIT_EXPRS.visit(falsy, state) : Ok(null);
      return Result.all(conditionResult, truthyResult, falsyResult).mapOk(_ref14 => {
        var [condition, truthy, falsy] = _ref14;
        if (inverted) {
          condition = new Not({
            value: condition,
            loc: node$$1.loc
          });
        }
        return new IfInline({
          loc: node$$1.loc,
          condition,
          truthy,
          falsy
        });
      });
    };
  }
  function ifUnlessInlineKeyword(type) {
    return {
      assert: assertIfUnlessInlineKeyword(type),
      translate: translateIfUnlessInlineKeyword(type)
    };
  }
  function assertLogKeyword(node$$1) {
    var {
      args: {
        named,
        positional
      }
    } = node$$1;
    if (named && !named.isEmpty()) {
      return Err((0, _syntax.generateSyntaxError)("(log) does not take any named arguments", node$$1.loc));
    }
    return Ok(positional);
  }
  function translateLogKeyword(_ref15, positional) {
    var {
      node: node$$1,
      state
    } = _ref15;
    return VISIT_EXPRS.Positional(positional, state).mapOk(positional => new Log({
      positional,
      loc: node$$1.loc
    }));
  }
  var logKeyword = {
    assert: assertLogKeyword,
    translate: translateLogKeyword
  };
  var CALL_KEYWORDS = keywords('Call').kw('has-block', hasBlockKeyword('has-block')).kw('has-block-params', hasBlockKeyword('has-block-params')).kw('-get-dynamic-var', getDynamicVarKeyword).kw('log', logKeyword).kw('if', ifUnlessInlineKeyword('if')).kw('unless', ifUnlessInlineKeyword('unless')).kw('component', curryKeyword(0
  /* Component */)).kw('helper', curryKeyword(1
  /* Helper */)).kw('modifier', curryKeyword(2
  /* Modifier */));

  function toAppend(_ref16) {
    var {
      assert,
      translate
    } = _ref16;
    return {
      assert,
      translate(_ref17, value) {
        var {
          node: node$$1,
          state
        } = _ref17;
        var result = translate({
          node: node$$1,
          state
        }, value);
        return result.mapOk(text => new AppendTextNode({
          text,
          loc: node$$1.loc
        }));
      }
    };
  }
  var APPEND_KEYWORDS = keywords('Append').kw('has-block', toAppend(hasBlockKeyword('has-block'))).kw('has-block-params', toAppend(hasBlockKeyword('has-block-params'))).kw('-get-dynamic-var', toAppend(getDynamicVarKeyword)).kw('log', toAppend(logKeyword)).kw('if', toAppend(ifUnlessInlineKeyword('if'))).kw('unless', toAppend(ifUnlessInlineKeyword('unless'))).kw('yield', {
    assert(node$$1) {
      var {
        args
      } = node$$1;
      if (args.named.isEmpty()) {
        return Ok({
          target: _syntax.SourceSpan.synthetic('default').toSlice(),
          positional: args.positional
        });
      } else {
        var target = args.named.get('to');
        if (args.named.size > 1 || target === null) {
          return Err((0, _syntax.generateSyntaxError)("yield only takes a single named argument: 'to'", args.named.loc));
        }
        if (_syntax.ASTv2.isLiteral(target, 'string')) {
          return Ok({
            target: target.toSlice(),
            positional: args.positional
          });
        } else {
          return Err((0, _syntax.generateSyntaxError)("you can only yield to a literal string value", target.loc));
        }
      }
    },
    translate(_ref18, _ref19) {
      var {
        node: node$$1,
        state
      } = _ref18;
      var {
        target,
        positional
      } = _ref19;
      return VISIT_EXPRS.Positional(positional, state).mapOk(positional => new Yield({
        loc: node$$1.loc,
        target,
        to: state.scope.allocateBlock(target.chars),
        positional
      }));
    }
  }).kw('debugger', {
    assert(node$$1) {
      var {
        args
      } = node$$1;
      var {
        positional
      } = args;
      if (args.isEmpty()) {
        return Ok(undefined);
      } else {
        if (positional.isEmpty()) {
          return Err((0, _syntax.generateSyntaxError)("debugger does not take any named arguments", node$$1.loc));
        } else {
          return Err((0, _syntax.generateSyntaxError)("debugger does not take any positional arguments", node$$1.loc));
        }
      }
    },
    translate(_ref20) {
      var {
        node: node$$1,
        state: {
          scope
        }
      } = _ref20;
      scope.setHasEval();
      return Ok(new Debugger({
        loc: node$$1.loc,
        scope
      }));
    }
  }).kw('component', {
    assert: assertCurryKeyword(0
    /* Component */),

    translate(_ref21, _ref22) {
      var {
        node: node$$1,
        state
      } = _ref21;
      var {
        definition,
        args
      } = _ref22;
      var definitionResult = VISIT_EXPRS.visit(definition, state);
      var argsResult = VISIT_EXPRS.Args(args, state);
      return Result.all(definitionResult, argsResult).mapOk(_ref23 => {
        var [definition, args] = _ref23;
        return new InvokeComponent({
          loc: node$$1.loc,
          definition,
          args,
          blocks: null
        });
      });
    }
  }).kw('helper', {
    assert: assertCurryKeyword(1
    /* Helper */),

    translate(_ref24, _ref25) {
      var {
        node: node$$1,
        state
      } = _ref24;
      var {
        definition,
        args
      } = _ref25;
      var definitionResult = VISIT_EXPRS.visit(definition, state);
      var argsResult = VISIT_EXPRS.Args(args, state);
      return Result.all(definitionResult, argsResult).mapOk(_ref26 => {
        var [definition, args] = _ref26;
        var text = new CallExpression({
          callee: definition,
          args,
          loc: node$$1.loc
        });
        return new AppendTextNode({
          loc: node$$1.loc,
          text
        });
      });
    }
  });
  var BLOCK_KEYWORDS = keywords('Block').kw('in-element', {
    assert(node$$1) {
      var {
        args
      } = node$$1;
      var guid = args.get('guid');
      if (guid) {
        return Err((0, _syntax.generateSyntaxError)("Cannot pass `guid` to `{{#in-element}}`", guid.loc));
      }
      var insertBefore = args.get('insertBefore');
      var destination = args.nth(0);
      if (destination === null) {
        return Err((0, _syntax.generateSyntaxError)("{{#in-element}} requires a target element as its first positional parameter", args.loc));
      } // TODO Better syntax checks

      return Ok({
        insertBefore,
        destination
      });
    },
    translate(_ref27, _ref28) {
      var {
        node: node$$1,
        state
      } = _ref27;
      var {
        insertBefore,
        destination
      } = _ref28;
      var named = node$$1.blocks.get('default');
      var body = VISIT_STMTS.NamedBlock(named, state);
      var destinationResult = VISIT_EXPRS.visit(destination, state);
      return Result.all(body, destinationResult).andThen(_ref29 => {
        var [body, destination] = _ref29;
        if (insertBefore) {
          return VISIT_EXPRS.visit(insertBefore, state).mapOk(insertBefore => ({
            body,
            destination,
            insertBefore
          }));
        } else {
          return Ok({
            body,
            destination,
            insertBefore: new Missing({
              loc: node$$1.callee.loc.collapse('end')
            })
          });
        }
      }).mapOk(_ref30 => {
        var {
          body,
          destination,
          insertBefore
        } = _ref30;
        return new InElement({
          loc: node$$1.loc,
          block: body,
          insertBefore,
          guid: state.generateUniqueCursor(),
          destination
        });
      });
    }
  }).kw('if', {
    assert(node$$1) {
      var {
        args
      } = node$$1;
      if (!args.named.isEmpty()) {
        return Err((0, _syntax.generateSyntaxError)("{{#if}} cannot receive named parameters, received " + args.named.entries.map(e => e.name.chars).join(', '), node$$1.loc));
      }
      if (args.positional.size > 1) {
        return Err((0, _syntax.generateSyntaxError)("{{#if}} can only receive one positional parameter in block form, the conditional value. Received " + args.positional.size + " parameters", node$$1.loc));
      }
      var condition = args.nth(0);
      if (condition === null) {
        return Err((0, _syntax.generateSyntaxError)("{{#if}} requires a condition as its first positional parameter, did not receive any parameters", node$$1.loc));
      }
      return Ok({
        condition
      });
    },
    translate(_ref31, _ref32) {
      var {
        node: node$$1,
        state
      } = _ref31;
      var {
        condition
      } = _ref32;
      var block = node$$1.blocks.get('default');
      var inverse = node$$1.blocks.get('else');
      var conditionResult = VISIT_EXPRS.visit(condition, state);
      var blockResult = VISIT_STMTS.NamedBlock(block, state);
      var inverseResult = inverse ? VISIT_STMTS.NamedBlock(inverse, state) : Ok(null);
      return Result.all(conditionResult, blockResult, inverseResult).mapOk(_ref33 => {
        var [condition, block, inverse] = _ref33;
        return new If({
          loc: node$$1.loc,
          condition,
          block,
          inverse
        });
      });
    }
  }).kw('unless', {
    assert(node$$1) {
      var {
        args
      } = node$$1;
      if (!args.named.isEmpty()) {
        return Err((0, _syntax.generateSyntaxError)("{{#unless}} cannot receive named parameters, received " + args.named.entries.map(e => e.name.chars).join(', '), node$$1.loc));
      }
      if (args.positional.size > 1) {
        return Err((0, _syntax.generateSyntaxError)("{{#unless}} can only receive one positional parameter in block form, the conditional value. Received " + args.positional.size + " parameters", node$$1.loc));
      }
      var condition = args.nth(0);
      if (condition === null) {
        return Err((0, _syntax.generateSyntaxError)("{{#unless}} requires a condition as its first positional parameter, did not receive any parameters", node$$1.loc));
      }
      return Ok({
        condition
      });
    },
    translate(_ref34, _ref35) {
      var {
        node: node$$1,
        state
      } = _ref34;
      var {
        condition
      } = _ref35;
      var block = node$$1.blocks.get('default');
      var inverse = node$$1.blocks.get('else');
      var conditionResult = VISIT_EXPRS.visit(condition, state);
      var blockResult = VISIT_STMTS.NamedBlock(block, state);
      var inverseResult = inverse ? VISIT_STMTS.NamedBlock(inverse, state) : Ok(null);
      return Result.all(conditionResult, blockResult, inverseResult).mapOk(_ref36 => {
        var [condition, block, inverse] = _ref36;
        return new If({
          loc: node$$1.loc,
          condition: new Not({
            value: condition,
            loc: node$$1.loc
          }),
          block,
          inverse
        });
      });
    }
  }).kw('each', {
    assert(node$$1) {
      var {
        args
      } = node$$1;
      if (!args.named.entries.every(e => e.name.chars === 'key')) {
        return Err((0, _syntax.generateSyntaxError)("{{#each}} can only receive the 'key' named parameter, received " + args.named.entries.filter(e => e.name.chars !== 'key').map(e => e.name.chars).join(', '), args.named.loc));
      }
      if (args.positional.size > 1) {
        return Err((0, _syntax.generateSyntaxError)("{{#each}} can only receive one positional parameter, the collection being iterated. Received " + args.positional.size + " parameters", args.positional.loc));
      }
      var value = args.nth(0);
      var key = args.get('key');
      if (value === null) {
        return Err((0, _syntax.generateSyntaxError)("{{#each}} requires an iterable value to be passed as its first positional parameter, did not receive any parameters", args.loc));
      }
      return Ok({
        value,
        key
      });
    },
    translate(_ref37, _ref38) {
      var {
        node: node$$1,
        state
      } = _ref37;
      var {
        value,
        key
      } = _ref38;
      var block = node$$1.blocks.get('default');
      var inverse = node$$1.blocks.get('else');
      var valueResult = VISIT_EXPRS.visit(value, state);
      var keyResult = key ? VISIT_EXPRS.visit(key, state) : Ok(null);
      var blockResult = VISIT_STMTS.NamedBlock(block, state);
      var inverseResult = inverse ? VISIT_STMTS.NamedBlock(inverse, state) : Ok(null);
      return Result.all(valueResult, keyResult, blockResult, inverseResult).mapOk(_ref39 => {
        var [value, key, block, inverse] = _ref39;
        return new Each({
          loc: node$$1.loc,
          value,
          key,
          block,
          inverse
        });
      });
    }
  }).kw('with', {
    assert(node$$1) {
      var {
        args
      } = node$$1;
      if (!args.named.isEmpty()) {
        return Err((0, _syntax.generateSyntaxError)("{{#with}} cannot receive named parameters, received " + args.named.entries.map(e => e.name.chars).join(', '), args.named.loc));
      }
      if (args.positional.size > 1) {
        return Err((0, _syntax.generateSyntaxError)("{{#with}} can only receive one positional parameter. Received " + args.positional.size + " parameters", args.positional.loc));
      }
      var value = args.nth(0);
      if (value === null) {
        return Err((0, _syntax.generateSyntaxError)("{{#with}} requires a value as its first positional parameter, did not receive any parameters", args.loc));
      }
      return Ok({
        value
      });
    },
    translate(_ref40, _ref41) {
      var {
        node: node$$1,
        state
      } = _ref40;
      var {
        value
      } = _ref41;
      var block = node$$1.blocks.get('default');
      var inverse = node$$1.blocks.get('else');
      var valueResult = VISIT_EXPRS.visit(value, state);
      var blockResult = VISIT_STMTS.NamedBlock(block, state);
      var inverseResult = inverse ? VISIT_STMTS.NamedBlock(inverse, state) : Ok(null);
      return Result.all(valueResult, blockResult, inverseResult).mapOk(_ref42 => {
        var [value, block, inverse] = _ref42;
        return new With({
          loc: node$$1.loc,
          value,
          block,
          inverse
        });
      });
    }
  }).kw('let', {
    assert(node$$1) {
      var {
        args
      } = node$$1;
      if (!args.named.isEmpty()) {
        return Err((0, _syntax.generateSyntaxError)("{{#let}} cannot receive named parameters, received " + args.named.entries.map(e => e.name.chars).join(', '), args.named.loc));
      }
      if (args.positional.size === 0) {
        return Err((0, _syntax.generateSyntaxError)("{{#let}} requires at least one value as its first positional parameter, did not receive any parameters", args.positional.loc));
      }
      if (node$$1.blocks.get('else')) {
        return Err((0, _syntax.generateSyntaxError)("{{#let}} cannot receive an {{else}} block", args.positional.loc));
      }
      return Ok({
        positional: args.positional
      });
    },
    translate(_ref43, _ref44) {
      var {
        node: node$$1,
        state
      } = _ref43;
      var {
        positional
      } = _ref44;
      var block = node$$1.blocks.get('default');
      var positionalResult = VISIT_EXPRS.Positional(positional, state);
      var blockResult = VISIT_STMTS.NamedBlock(block, state);
      return Result.all(positionalResult, blockResult).mapOk(_ref45 => {
        var [positional, block] = _ref45;
        return new Let({
          loc: node$$1.loc,
          positional,
          block
        });
      });
    }
  }).kw('-with-dynamic-vars', {
    assert(node$$1) {
      return Ok({
        named: node$$1.args.named
      });
    },
    translate(_ref46, _ref47) {
      var {
        node: node$$1,
        state
      } = _ref46;
      var {
        named
      } = _ref47;
      var block = node$$1.blocks.get('default');
      var namedResult = VISIT_EXPRS.NamedArguments(named, state);
      var blockResult = VISIT_STMTS.NamedBlock(block, state);
      return Result.all(namedResult, blockResult).mapOk(_ref48 => {
        var [named, block] = _ref48;
        return new WithDynamicVars({
          loc: node$$1.loc,
          named,
          block
        });
      });
    }
  }).kw('component', {
    assert: assertCurryKeyword(0
    /* Component */),

    translate(_ref49, _ref50) {
      var {
        node: node$$1,
        state
      } = _ref49;
      var {
        definition,
        args
      } = _ref50;
      var definitionResult = VISIT_EXPRS.visit(definition, state);
      var argsResult = VISIT_EXPRS.Args(args, state);
      var blocksResult = VISIT_STMTS.NamedBlocks(node$$1.blocks, state);
      return Result.all(definitionResult, argsResult, blocksResult).mapOk(_ref51 => {
        var [definition, args, blocks] = _ref51;
        return new InvokeComponent({
          loc: node$$1.loc,
          definition,
          args,
          blocks
        });
      });
    }
  });
  var MODIFIER_KEYWORDS = keywords('Modifier');

  // There is a small whitelist of namespaced attributes specially
  // enumerated in
  // https://www.w3.org/TR/html/syntax.html#attributes-0
  //
  // > When a foreign element has one of the namespaced attributes given by
  // > the local name and namespace of the first and second cells of a row
  // > from the following table, it must be written using the name given by
  // > the third cell from the same row.
  //
  // In all other cases, colons are interpreted as a regular character
  // with no special meaning:
  //
  // > No other namespaced attribute can be expressed in the HTML syntax.
  var XLINK = 'http://www.w3.org/1999/xlink';
  var XML = 'http://www.w3.org/XML/1998/namespace';
  var XMLNS = 'http://www.w3.org/2000/xmlns/';
  var WHITELIST = {
    'xlink:actuate': XLINK,
    'xlink:arcrole': XLINK,
    'xlink:href': XLINK,
    'xlink:role': XLINK,
    'xlink:show': XLINK,
    'xlink:title': XLINK,
    'xlink:type': XLINK,
    'xml:base': XML,
    'xml:lang': XML,
    'xml:space': XML,
    xmlns: XMLNS,
    'xmlns:xlink': XMLNS
  };
  function getAttrNamespace(attrName) {
    return WHITELIST[attrName];
  }
  var DEFLATE_TAG_TABLE = {
    div: 0
    /* div */,

    span: 1
    /* span */,

    p: 2
    /* p */,

    a: 3
    /* a */
  };

  var INFLATE_TAG_TABLE = ['div', 'span', 'p', 'a'];
  function deflateTagName(tagName) {
    var _a;
    return (_a = DEFLATE_TAG_TABLE[tagName]) !== null && _a !== void 0 ? _a : tagName;
  }
  function inflateTagName(tagName) {
    return typeof tagName === 'string' ? tagName : INFLATE_TAG_TABLE[tagName];
  }
  var DEFLATE_ATTR_TABLE = {
    class: 0
    /* class */,

    id: 1
    /* id */,

    value: 2
    /* value */,

    name: 3
    /* name */,

    type: 4
    /* type */,

    style: 5
    /* style */,

    href: 6
    /* href */
  };

  var INFLATE_ATTR_TABLE = ['class', 'id', 'value', 'name', 'type', 'style', 'href'];
  function deflateAttrName(attrName) {
    var _a;
    return (_a = DEFLATE_ATTR_TABLE[attrName]) !== null && _a !== void 0 ? _a : attrName;
  }
  function inflateAttrName(attrName) {
    return typeof attrName === 'string' ? attrName : INFLATE_ATTR_TABLE[attrName];
  }
  class ClassifiedElement {
    constructor(element, delegate, state) {
      this.element = element;
      this.state = state;
      this.delegate = delegate;
    }
    toStatement() {
      return this.prepare().andThen(prepared => this.delegate.toStatement(this, prepared));
    }
    attr(attr) {
      var name = attr.name;
      var rawValue = attr.value;
      var namespace = getAttrNamespace(name.chars) || undefined;
      if (_syntax.ASTv2.isLiteral(rawValue, 'string')) {
        return Ok(new StaticAttr({
          loc: attr.loc,
          name,
          value: rawValue.toSlice(),
          namespace,
          kind: {
            component: this.delegate.dynamicFeatures
          }
        }));
      }
      return VISIT_EXPRS.visit(convertPathToCallIfKeyword(rawValue), this.state).mapOk(value => {
        var isTrusting = attr.trusting;
        return new DynamicAttr({
          loc: attr.loc,
          name,
          value: value,
          namespace,
          kind: {
            trusting: isTrusting,
            component: this.delegate.dynamicFeatures
          }
        });
      });
    }
    modifier(modifier) {
      if (isHelperInvocation(modifier)) {
        assertIsValidModifier(modifier);
      }
      var translated = MODIFIER_KEYWORDS.translate(modifier, this.state);
      if (translated !== null) {
        return translated;
      }
      var head = VISIT_EXPRS.visit(modifier.callee, this.state);
      var args = VISIT_EXPRS.Args(modifier.args, this.state);
      return Result.all(head, args).mapOk(_ref52 => {
        var [head, args] = _ref52;
        return new Modifier({
          loc: modifier.loc,
          callee: head,
          args
        });
      });
    }
    attrs() {
      var attrs = new ResultArray();
      var args = new ResultArray(); // Unlike most attributes, the `type` attribute can change how
      // subsequent attributes are interpreted by the browser. To address
      // this, in simple cases, we special case the `type` attribute to be set
      // last. For elements with splattributes, where attribute order affects
      // precedence, this re-ordering happens at runtime instead.
      // See https://github.com/glimmerjs/glimmer-vm/pull/726

      var typeAttr = null;
      var simple = this.element.attrs.filter(attr => attr.type === 'SplatAttr').length === 0;
      for (var attr of this.element.attrs) {
        if (attr.type === 'SplatAttr') {
          attrs.add(Ok(new SplatAttr({
            loc: attr.loc,
            symbol: this.state.scope.allocateBlock('attrs')
          })));
        } else if (attr.name.chars === 'type' && simple) {
          typeAttr = attr;
        } else {
          attrs.add(this.attr(attr));
        }
      }
      for (var arg of this.element.componentArgs) {
        args.add(this.delegate.arg(arg, this));
      }
      if (typeAttr) {
        attrs.add(this.attr(typeAttr));
      }
      return Result.all(args.toArray(), attrs.toArray()).mapOk(_ref53 => {
        var [args, attrs] = _ref53;
        return {
          attrs,
          args: new NamedArguments({
            loc: (0, _syntax.maybeLoc)(args, _syntax.SourceSpan.NON_EXISTENT),
            entries: OptionalList(args)
          })
        };
      });
    }
    prepare() {
      var attrs = this.attrs();
      var modifiers = new ResultArray(this.element.modifiers.map(m => this.modifier(m))).toArray();
      return Result.all(attrs, modifiers).mapOk(_ref54 => {
        var [result, modifiers] = _ref54;
        var {
          attrs,
          args
        } = result;
        var elementParams = [...attrs, ...modifiers];
        var params = new ElementParameters({
          loc: (0, _syntax.maybeLoc)(elementParams, _syntax.SourceSpan.NON_EXISTENT),
          body: OptionalList(elementParams)
        });
        return {
          args,
          params
        };
      });
    }
  }
  function hasDynamicFeatures(_ref55) {
    var {
      attrs,
      modifiers
    } = _ref55;
    // ElementModifier needs the special ComponentOperations
    if (modifiers.length > 0) {
      return true;
    } // Splattributes need the special ComponentOperations to merge into

    return !!attrs.filter(attr => attr.type === 'SplatAttr')[0];
  }
  class ClassifiedComponent {
    constructor(tag, element) {
      this.tag = tag;
      this.element = element;
      this.dynamicFeatures = true;
    }
    arg(attr, _ref56) {
      var {
        state
      } = _ref56;
      var name = attr.name;
      return VISIT_EXPRS.visit(convertPathToCallIfKeyword(attr.value), state).mapOk(value => new NamedArgument({
        loc: attr.loc,
        key: name,
        value
      }));
    }
    toStatement(component, _ref57) {
      var {
        args,
        params
      } = _ref57;
      var {
        element,
        state
      } = component;
      return this.blocks(state).mapOk(blocks => new Component({
        loc: element.loc,
        tag: this.tag,
        params,
        args,
        blocks
      }));
    }
    blocks(state) {
      return VISIT_STMTS.NamedBlocks(this.element.blocks, state);
    }
  }
  class ClassifiedSimpleElement {
    constructor(tag, element, dynamicFeatures) {
      this.tag = tag;
      this.element = element;
      this.dynamicFeatures = dynamicFeatures;
      this.isComponent = false;
    }
    arg(attr) {
      return Err((0, _syntax.generateSyntaxError)(attr.name.chars + " is not a valid attribute name. @arguments are only allowed on components, but the tag for this element (`" + this.tag.chars + "`) is a regular, non-component HTML element.", attr.loc));
    }
    toStatement(classified, _ref58) {
      var {
        params
      } = _ref58;
      var {
        state,
        element
      } = classified;
      var body = VISIT_STMTS.visitList(this.element.body, state);
      return body.mapOk(body => new SimpleElement({
        loc: element.loc,
        tag: this.tag,
        params,
        body: body.toArray(),
        dynamicFeatures: this.dynamicFeatures
      }));
    }
  }
  class NormalizationStatements {
    visitList(nodes, state) {
      return new ResultArray(nodes.map(e => VISIT_STMTS.visit(e, state))).toOptionalList().mapOk(list => list.filter(s => s !== null));
    }
    visit(node$$1, state) {
      switch (node$$1.type) {
        case 'GlimmerComment':
          return Ok(null);
        case 'AppendContent':
          return this.AppendContent(node$$1, state);
        case 'HtmlText':
          return Ok(this.TextNode(node$$1));
        case 'HtmlComment':
          return Ok(this.HtmlComment(node$$1));
        case 'InvokeBlock':
          return this.InvokeBlock(node$$1, state);
        case 'InvokeComponent':
          return this.Component(node$$1, state);
        case 'SimpleElement':
          return this.SimpleElement(node$$1, state);
      }
    }
    InvokeBlock(node$$1, state) {
      var translated = BLOCK_KEYWORDS.translate(node$$1, state);
      if (translated !== null) {
        return translated;
      }
      var head = VISIT_EXPRS.visit(node$$1.callee, state);
      var args = VISIT_EXPRS.Args(node$$1.args, state);
      return Result.all(head, args).andThen(_ref59 => {
        var [head, args] = _ref59;
        return this.NamedBlocks(node$$1.blocks, state).mapOk(blocks => new InvokeBlock({
          loc: node$$1.loc,
          head,
          args,
          blocks
        }));
      });
    }
    NamedBlocks(blocks, state) {
      var list = new ResultArray(blocks.blocks.map(b => this.NamedBlock(b, state)));
      return list.toArray().mapOk(list => new NamedBlocks({
        loc: blocks.loc,
        blocks: OptionalList(list)
      }));
    }
    NamedBlock(named, state) {
      var body = state.visitBlock(named.block);
      return body.mapOk(body => {
        return new NamedBlock({
          loc: named.loc,
          name: named.name,
          body: body.toArray(),
          scope: named.block.scope
        });
      });
    }
    SimpleElement(element, state) {
      return new ClassifiedElement(element, new ClassifiedSimpleElement(element.tag, element, hasDynamicFeatures(element)), state).toStatement();
    }
    Component(component, state) {
      return VISIT_EXPRS.visit(component.callee, state).andThen(callee => new ClassifiedElement(component, new ClassifiedComponent(callee, component), state).toStatement());
    }
    AppendContent(append, state) {
      var translated = APPEND_KEYWORDS.translate(append, state);
      if (translated !== null) {
        return translated;
      }
      var value = VISIT_EXPRS.visit(append.value, state);
      return value.mapOk(value => {
        if (append.trusting) {
          return new AppendTrustedHTML({
            loc: append.loc,
            html: value
          });
        } else {
          return new AppendTextNode({
            loc: append.loc,
            text: value
          });
        }
      });
    }
    TextNode(text) {
      return new AppendTextNode({
        loc: text.loc,
        text: new _syntax.ASTv2.LiteralExpression({
          loc: text.loc,
          value: text.chars
        })
      });
    }
    HtmlComment(comment) {
      return new AppendComment({
        loc: comment.loc,
        value: comment.text
      });
    }
  }
  var VISIT_STMTS = new NormalizationStatements();

  /**
   * This is the mutable state for this compiler pass.
   */

  class NormalizationState {
    constructor(block, isStrict) {
      this.isStrict = isStrict;
      this._cursorCount = 0;
      this._currentScope = block;
    }
    generateUniqueCursor() {
      return "%cursor:" + this._cursorCount++ + "%";
    }
    get scope() {
      return this._currentScope;
    }
    visitBlock(block) {
      var oldBlock = this._currentScope;
      this._currentScope = block.scope;
      try {
        return VISIT_STMTS.visitList(block.body, this);
      } finally {
        this._currentScope = oldBlock;
      }
    }
  }

  /**
   * Normalize the AST from @glimmer/syntax into the HIR. The HIR has special
   * instructions for keywords like `{{yield}}`, `(has-block)` and
   * `{{#in-element}}`.
   *
   * Most importantly, it also classifies HTML element syntax into:
   *
   * 1. simple HTML element (with optional splattributes)
   * 2. component invocation
   *
   * Because the @glimmer/syntax AST gives us a string for an element's tag,
   * this pass also normalizes that string into an expression.
   *
   * ```
   * // normalized into a path expression whose head is `this` and tail is
   * // `["x"]`
   * <this.x />
   *
   * {{#let expr as |t|}}
   *   // `"t"` is normalized into a variable lookup.
   *   <t />
   *
   *   // normalized into a path expression whose head is the variable lookup
   *   // `t` and tail is `["input"]`.
   *   <t.input />
   * {{/let}}
   *
   * // normalized into a free variable lookup for `SomeComponent` (with the
   * // context `ComponentHead`).
   * <SomeComponent />
   *
   * // normalized into a path expression whose head is the free variable
   * // `notInScope` (with the context `Expression`), and whose tail is
   * // `["SomeComponent"]`. In resolver mode, this path will be rejected later,
   * // since it cannot serve as an input to the resolver.
   * <notInScope.SomeComponent />
   * ```
   */

  function normalize$1(source, root, isStrict) {
    // create a new context for the normalization pass
    var state = new NormalizationState(root.table, isStrict);
    var body = VISIT_STMTS.visitList(root.body, state);
    return body.mapOk(body => new Template({
      loc: root.loc,
      scope: root.table,
      body: body.toArray()
    }));
  }
  class WireFormatDebugger {
    constructor(_ref60) {
      var [_statements, symbols, _hasEval, upvars] = _ref60;
      this.upvars = upvars;
      this.symbols = symbols;
    }
    format(program) {
      var out = [];
      for (var statement of program[0]) {
        out.push(this.formatOpcode(statement));
      }
      return out;
    }
    formatOpcode(opcode) {
      if (Array.isArray(opcode)) {
        switch (opcode[0]) {
          case 1
          /* Append */:
            return ['append', this.formatOpcode(opcode[1])];
          case 2
          /* TrustingAppend */:
            return ['trusting-append', this.formatOpcode(opcode[1])];
          case 6
          /* Block */:
            return ['block', this.formatOpcode(opcode[1]), this.formatParams(opcode[2]), this.formatHash(opcode[3]), this.formatBlocks(opcode[4])];
          case 40
          /* InElement */:
            return ['in-element', opcode[1], this.formatOpcode(opcode[2]), opcode[3] ? this.formatOpcode(opcode[3]) : undefined];
          case 10
          /* OpenElement */:
            return ['open-element', inflateTagName(opcode[1])];
          case 11
          /* OpenElementWithSplat */:
            return ['open-element-with-splat', inflateTagName(opcode[1])];
          case 13
          /* CloseElement */:
            return ['close-element'];
          case 12
          /* FlushElement */:
            return ['flush-element'];
          case 14
          /* StaticAttr */:
            return ['static-attr', inflateAttrName(opcode[1]), opcode[2], opcode[3]];
          case 24
          /* StaticComponentAttr */:
            return ['static-component-attr', inflateAttrName(opcode[1]), opcode[2], opcode[3]];
          case 15
          /* DynamicAttr */:
            return ['dynamic-attr', inflateAttrName(opcode[1]), this.formatOpcode(opcode[2]), opcode[3]];
          case 16
          /* ComponentAttr */:
            return ['component-attr', inflateAttrName(opcode[1]), this.formatOpcode(opcode[2]), opcode[3]];
          case 17
          /* AttrSplat */:
            return ['attr-splat'];
          case 18
          /* Yield */:
            return ['yield', opcode[1], this.formatParams(opcode[2])];
          case 20
          /* DynamicArg */:
            return ['dynamic-arg', opcode[1], this.formatOpcode(opcode[2])];
          case 21
          /* StaticArg */:
            return ['static-arg', opcode[1], this.formatOpcode(opcode[2])];
          case 22
          /* TrustingDynamicAttr */:
            return ['trusting-dynamic-attr', inflateAttrName(opcode[1]), this.formatOpcode(opcode[2]), opcode[3]];
          case 23
          /* TrustingComponentAttr */:
            return ['trusting-component-attr', inflateAttrName(opcode[1]), this.formatOpcode(opcode[2]), opcode[3]];
          case 26
          /* Debugger */:
            return ['debugger', opcode[1]];
          case 3
          /* Comment */:
            return ['comment', opcode[1]];
          case 4
          /* Modifier */:
            return ['modifier', this.formatOpcode(opcode[1]), this.formatParams(opcode[2]), this.formatHash(opcode[3])];
          case 8
          /* Component */:
            return ['component', this.formatOpcode(opcode[1]), this.formatElementParams(opcode[2]), this.formatHash(opcode[3]), this.formatBlocks(opcode[4])];
          case 48
          /* HasBlock */:
            return ['has-block', this.formatOpcode(opcode[1])];
          case 49
          /* HasBlockParams */:
            return ['has-block-params', this.formatOpcode(opcode[1])];
          case 50
          /* Curry */:
            return ['curry', this.formatOpcode(opcode[1]), this.formatCurryType(opcode[2]), this.formatParams(opcode[3]), this.formatHash(opcode[4])];
          case 27
          /* Undefined */:
            return ['undefined'];
          case 28
          /* Call */:
            return ['call', this.formatOpcode(opcode[1]), this.formatParams(opcode[2]), this.formatHash(opcode[3])];
          case 29
          /* Concat */:
            return ['concat', this.formatParams(opcode[1])];
          case 31
          /* GetStrictFree */:
            return ['get-strict-free', this.upvars[opcode[1]], opcode[2]];
          case 34
          /* GetFreeAsComponentOrHelperHeadOrThisFallback */:
            return ['GetFreeAsComponentOrHelperHeadOrThisFallback', this.upvars[opcode[1]], opcode[2]];
          case 35
          /* GetFreeAsComponentOrHelperHead */:
            return ['GetFreeAsComponentOrHelperHead', this.upvars[opcode[1]], opcode[2]];
          case 36
          /* GetFreeAsHelperHeadOrThisFallback */:
            return ['GetFreeAsHelperHeadOrThisFallback', this.upvars[opcode[1]], opcode[2]];
          case 99
          /* GetFreeAsDeprecatedHelperHeadOrThisFallback */:
            return ['GetFreeAsDeprecatedHelperHeadOrThisFallback', this.upvars[opcode[1]]];
          case 37
          /* GetFreeAsHelperHead */:
            return ['GetFreeAsHelperHead', this.upvars[opcode[1]], opcode[2]];
          case 39
          /* GetFreeAsComponentHead */:
            return ['GetFreeAsComponentHead', this.upvars[opcode[1]], opcode[2]];
          case 38
          /* GetFreeAsModifierHead */:
            return ['GetFreeAsModifierHead', this.upvars[opcode[1]], opcode[2]];
          case 30
          /* GetSymbol */:
            {
              if (opcode[1] === 0) {
                return ['get-symbol', 'this', opcode[2]];
              } else {
                return ['get-symbol', this.symbols[opcode[1] - 1], opcode[2]];
              }
            }
          case 32
          /* GetTemplateSymbol */:
            {
              return ['get-template-symbol', opcode[1], opcode[2]];
            }
          case 41
          /* If */:
            return ['if', this.formatOpcode(opcode[1]), this.formatBlock(opcode[2]), opcode[3] ? this.formatBlock(opcode[3]) : null];
          case 52
          /* IfInline */:
            return ['if-inline'];
          case 51
          /* Not */:
            return ['not'];
          case 42
          /* Each */:
            return ['each', this.formatOpcode(opcode[1]), opcode[2] ? this.formatOpcode(opcode[2]) : null, this.formatBlock(opcode[3]), opcode[4] ? this.formatBlock(opcode[4]) : null];
          case 43
          /* With */:
            return ['with', this.formatOpcode(opcode[1]), this.formatBlock(opcode[2]), opcode[3] ? this.formatBlock(opcode[3]) : null];
          case 44
          /* Let */:
            return ['let', this.formatParams(opcode[1]), this.formatBlock(opcode[2])];
          case 54
          /* Log */:
            return ['log', this.formatParams(opcode[1])];
          case 45
          /* WithDynamicVars */:
            return ['-with-dynamic-vars', this.formatHash(opcode[1]), this.formatBlock(opcode[2])];
          case 53
          /* GetDynamicVar */:
            return ['-get-dynamic-vars', this.formatOpcode(opcode[1])];
          case 46
          /* InvokeComponent */:
            return ['component', this.formatOpcode(opcode[1]), this.formatParams(opcode[2]), this.formatHash(opcode[3]), this.formatBlocks(opcode[4])];
        }
      } else {
        return opcode;
      }
    }
    formatCurryType(value) {
      switch (value) {
        case 0
        /* Component */:
          return 'component';
        case 1
        /* Helper */:
          return 'helper';
        case 2
        /* Modifier */:
          return 'modifier';
        default:
          throw (0, _util.exhausted)(value);
      }
    }
    formatElementParams(opcodes) {
      if (opcodes === null) return null;
      return opcodes.map(o => this.formatOpcode(o));
    }
    formatParams(opcodes) {
      if (opcodes === null) return null;
      return opcodes.map(o => this.formatOpcode(o));
    }
    formatHash(hash) {
      if (hash === null) return null;
      return hash[0].reduce((accum, key, index) => {
        accum[key] = this.formatOpcode(hash[1][index]);
        return accum;
      }, (0, _util.dict)());
    }
    formatBlocks(blocks) {
      if (blocks === null) return null;
      return blocks[0].reduce((accum, key, index) => {
        accum[key] = this.formatBlock(blocks[1][index]);
        return accum;
      }, (0, _util.dict)());
    }
    formatBlock(block) {
      return {
        statements: block[0].map(s => this.formatOpcode(s)),
        parameters: block[1]
      };
    }
  }
  _exports.WireFormatDebugger = WireFormatDebugger;
  class ExpressionEncoder {
    expr(expr) {
      switch (expr.type) {
        case 'Missing':
          return undefined;
        case 'Literal':
          return this.Literal(expr);
        case 'CallExpression':
          return this.CallExpression(expr);
        case 'DeprecatedCallExpression':
          return this.DeprecatedCallExpression(expr);
        case 'PathExpression':
          return this.PathExpression(expr);
        case 'Arg':
          return [30
          /* GetSymbol */, expr.symbol];
        case 'Local':
          return this.Local(expr);
        case 'This':
          return [30
          /* GetSymbol */, 0];
        case 'Free':
          return [expr.resolution.resolution(), expr.symbol];
        case 'HasBlock':
          return this.HasBlock(expr);
        case 'HasBlockParams':
          return this.HasBlockParams(expr);
        case 'Curry':
          return this.Curry(expr);
        case 'Not':
          return this.Not(expr);
        case 'IfInline':
          return this.IfInline(expr);
        case 'InterpolateExpression':
          return this.InterpolateExpression(expr);
        case 'GetDynamicVar':
          return this.GetDynamicVar(expr);
        case 'Log':
          return this.Log(expr);
      }
    }
    Literal(_ref61) {
      var {
        value
      } = _ref61;
      if (value === undefined) {
        return [27
        /* Undefined */];
      } else {
        return value;
      }
    }
    Missing() {
      return undefined;
    }
    HasBlock(_ref62) {
      var {
        symbol
      } = _ref62;
      return [48
      /* HasBlock */, [30
      /* GetSymbol */, symbol]];
    }
    HasBlockParams(_ref63) {
      var {
        symbol
      } = _ref63;
      return [49
      /* HasBlockParams */, [30
      /* GetSymbol */, symbol]];
    }
    Curry(_ref64) {
      var {
        definition,
        curriedType,
        args
      } = _ref64;
      return [50
      /* Curry */, EXPR.expr(definition), curriedType, EXPR.Positional(args.positional), EXPR.NamedArguments(args.named)];
    }
    Local(_ref65) {
      var {
        isTemplateLocal,
        symbol
      } = _ref65;
      return [isTemplateLocal ? 32
      /* GetTemplateSymbol */ : 30
      /* GetSymbol */, symbol];
    }
    GetWithResolver(_ref66) {
      var {
        symbol
      } = _ref66;
      return [34
      /* GetFreeAsComponentOrHelperHeadOrThisFallback */, symbol];
    }
    PathExpression(_ref67) {
      var {
        head,
        tail
      } = _ref67;
      var getOp = EXPR.expr(head);
      return [...getOp, EXPR.Tail(tail)];
    }
    InterpolateExpression(_ref68) {
      var {
        parts
      } = _ref68;
      return [29
      /* Concat */, parts.map(e => EXPR.expr(e)).toArray()];
    }
    CallExpression(_ref69) {
      var {
        callee,
        args
      } = _ref69;
      return [28
      /* Call */, EXPR.expr(callee), ...EXPR.Args(args)];
    }
    DeprecatedCallExpression(_ref70) {
      var {
        arg,
        callee
      } = _ref70;
      return [99
      /* GetFreeAsDeprecatedHelperHeadOrThisFallback */, callee.symbol, [arg.chars]];
    }
    Tail(_ref71) {
      var {
        members
      } = _ref71;
      return (0, _util.mapPresent)(members, member => member.chars);
    }
    Args(_ref72) {
      var {
        positional,
        named
      } = _ref72;
      return [this.Positional(positional), this.NamedArguments(named)];
    }
    Positional(_ref73) {
      var {
        list
      } = _ref73;
      return list.map(l => EXPR.expr(l)).toPresentArray();
    }
    NamedArgument(_ref74) {
      var {
        key,
        value
      } = _ref74;
      return [key.chars, EXPR.expr(value)];
    }
    NamedArguments(_ref75) {
      var {
        entries: pairs
      } = _ref75;
      var list = pairs.toArray();
      if ((0, _util.isPresent)(list)) {
        var names = [];
        var values$$1 = [];
        for (var pair of list) {
          var [name, value] = EXPR.NamedArgument(pair);
          names.push(name);
          values$$1.push(value);
        }
        (0, _util.assertPresent)(names);
        (0, _util.assertPresent)(values$$1);
        return [names, values$$1];
      } else {
        return null;
      }
    }
    Not(_ref76) {
      var {
        value
      } = _ref76;
      return [51
      /* Not */, EXPR.expr(value)];
    }
    IfInline(_ref77) {
      var {
        condition,
        truthy,
        falsy
      } = _ref77;
      var expr = [52
      /* IfInline */, EXPR.expr(condition), EXPR.expr(truthy)];
      if (falsy) {
        expr.push(EXPR.expr(falsy));
      }
      return expr;
    }
    GetDynamicVar(_ref78) {
      var {
        name
      } = _ref78;
      return [53
      /* GetDynamicVar */, EXPR.expr(name)];
    }
    Log(_ref79) {
      var {
        positional
      } = _ref79;
      return [54
      /* Log */, this.Positional(positional)];
    }
  }
  var EXPR = new ExpressionEncoder();
  class WireStatements {
    constructor(statements) {
      this.statements = statements;
    }
    toArray() {
      return this.statements;
    }
  }
  class ContentEncoder {
    list(statements) {
      var out = [];
      for (var statement of statements) {
        var result = CONTENT.content(statement);
        if (result && result instanceof WireStatements) {
          out.push(...result.toArray());
        } else {
          out.push(result);
        }
      }
      return out;
    }
    content(stmt) {
      return this.visitContent(stmt);
    }
    visitContent(stmt) {
      switch (stmt.type) {
        case 'Debugger':
          return [26
          /* Debugger */, stmt.scope.getEvalInfo()];
        case 'AppendComment':
          return this.AppendComment(stmt);
        case 'AppendTextNode':
          return this.AppendTextNode(stmt);
        case 'AppendTrustedHTML':
          return this.AppendTrustedHTML(stmt);
        case 'Yield':
          return this.Yield(stmt);
        case 'Component':
          return this.Component(stmt);
        case 'SimpleElement':
          return this.SimpleElement(stmt);
        case 'InElement':
          return this.InElement(stmt);
        case 'InvokeBlock':
          return this.InvokeBlock(stmt);
        case 'If':
          return this.If(stmt);
        case 'Each':
          return this.Each(stmt);
        case 'With':
          return this.With(stmt);
        case 'Let':
          return this.Let(stmt);
        case 'WithDynamicVars':
          return this.WithDynamicVars(stmt);
        case 'InvokeComponent':
          return this.InvokeComponent(stmt);
        default:
          return (0, _util.exhausted)(stmt);
      }
    }
    Yield(_ref80) {
      var {
        to,
        positional
      } = _ref80;
      return [18
      /* Yield */, to, EXPR.Positional(positional)];
    }
    InElement(_ref81) {
      var {
        guid,
        insertBefore,
        destination,
        block
      } = _ref81;
      var wireBlock = CONTENT.NamedBlock(block)[1]; // let guid = args.guid;

      var wireDestination = EXPR.expr(destination);
      var wireInsertBefore = EXPR.expr(insertBefore);
      if (wireInsertBefore === undefined) {
        return [40
        /* InElement */, wireBlock, guid, wireDestination];
      } else {
        return [40
        /* InElement */, wireBlock, guid, wireDestination, wireInsertBefore];
      }
    }
    InvokeBlock(_ref82) {
      var {
        head,
        args,
        blocks
      } = _ref82;
      return [6
      /* Block */, EXPR.expr(head), ...EXPR.Args(args), CONTENT.NamedBlocks(blocks)];
    }
    AppendTrustedHTML(_ref83) {
      var {
        html
      } = _ref83;
      return [2
      /* TrustingAppend */, EXPR.expr(html)];
    }
    AppendTextNode(_ref84) {
      var {
        text
      } = _ref84;
      return [1
      /* Append */, EXPR.expr(text)];
    }
    AppendComment(_ref85) {
      var {
        value
      } = _ref85;
      return [3
      /* Comment */, value.chars];
    }
    SimpleElement(_ref86) {
      var {
        tag,
        params,
        body,
        dynamicFeatures
      } = _ref86;
      var op = dynamicFeatures ? 11
      /* OpenElementWithSplat */ : 10
      /* OpenElement */;

      return new WireStatements([[op, deflateTagName(tag.chars)], ...CONTENT.ElementParameters(params).toArray(), [12
      /* FlushElement */], ...CONTENT.list(body), [13
      /* CloseElement */]]);
    }

    Component(_ref87) {
      var {
        tag,
        params,
        args,
        blocks
      } = _ref87;
      var wireTag = EXPR.expr(tag);
      var wirePositional = CONTENT.ElementParameters(params);
      var wireNamed = EXPR.NamedArguments(args);
      var wireNamedBlocks = CONTENT.NamedBlocks(blocks);
      return [8
      /* Component */, wireTag, wirePositional.toPresentArray(), wireNamed, wireNamedBlocks];
    }
    ElementParameters(_ref88) {
      var {
        body
      } = _ref88;
      return body.map(p => CONTENT.ElementParameter(p));
    }
    ElementParameter(param) {
      switch (param.type) {
        case 'SplatAttr':
          return [17
          /* AttrSplat */, param.symbol];
        case 'DynamicAttr':
          return [dynamicAttrOp(param.kind), ...dynamicAttr(param)];
        case 'StaticAttr':
          return [staticAttrOp(param.kind), ...staticAttr(param)];
        case 'Modifier':
          return [4
          /* Modifier */, EXPR.expr(param.callee), ...EXPR.Args(param.args)];
      }
    }
    NamedBlocks(_ref89) {
      var {
        blocks
      } = _ref89;
      var names = [];
      var serializedBlocks = [];
      for (var block of blocks.toArray()) {
        var [name, serializedBlock] = CONTENT.NamedBlock(block);
        names.push(name);
        serializedBlocks.push(serializedBlock);
      }
      return names.length > 0 ? [names, serializedBlocks] : null;
    }
    NamedBlock(_ref90) {
      var {
        name,
        body,
        scope
      } = _ref90;
      var nameChars = name.chars;
      if (nameChars === 'inverse') {
        nameChars = 'else';
      }
      return [nameChars, [CONTENT.list(body), scope.slots]];
    }
    If(_ref91) {
      var {
        condition,
        block,
        inverse
      } = _ref91;
      return [41
      /* If */, EXPR.expr(condition), CONTENT.NamedBlock(block)[1], inverse ? CONTENT.NamedBlock(inverse)[1] : null];
    }
    Each(_ref92) {
      var {
        value,
        key,
        block,
        inverse
      } = _ref92;
      return [42
      /* Each */, EXPR.expr(value), key ? EXPR.expr(key) : null, CONTENT.NamedBlock(block)[1], inverse ? CONTENT.NamedBlock(inverse)[1] : null];
    }
    With(_ref93) {
      var {
        value,
        block,
        inverse
      } = _ref93;
      return [43
      /* With */, EXPR.expr(value), CONTENT.NamedBlock(block)[1], inverse ? CONTENT.NamedBlock(inverse)[1] : null];
    }
    Let(_ref94) {
      var {
        positional,
        block
      } = _ref94;
      return [44
      /* Let */, EXPR.Positional(positional), CONTENT.NamedBlock(block)[1]];
    }
    WithDynamicVars(_ref95) {
      var {
        named,
        block
      } = _ref95;
      return [45
      /* WithDynamicVars */, EXPR.NamedArguments(named), CONTENT.NamedBlock(block)[1]];
    }
    InvokeComponent(_ref96) {
      var {
        definition,
        args,
        blocks
      } = _ref96;
      return [46
      /* InvokeComponent */, EXPR.expr(definition), EXPR.Positional(args.positional), EXPR.NamedArguments(args.named), blocks ? CONTENT.NamedBlocks(blocks) : null];
    }
  }
  var CONTENT = new ContentEncoder();
  function staticAttr(_ref97) {
    var {
      name,
      value,
      namespace
    } = _ref97;
    var out = [deflateAttrName(name.chars), value.chars];
    if (namespace) {
      out.push(namespace);
    }
    return out;
  }
  function dynamicAttr(_ref98) {
    var {
      name,
      value,
      namespace
    } = _ref98;
    var out = [deflateAttrName(name.chars), EXPR.expr(value)];
    if (namespace) {
      out.push(namespace);
    }
    return out;
  }
  function staticAttrOp(kind) {
    if (kind.component) {
      return 24
      /* StaticComponentAttr */;
    } else {
      return 14
      /* StaticAttr */;
    }
  }

  function dynamicAttrOp(kind) {
    if (kind.component) {
      return kind.trusting ? 23
      /* TrustingComponentAttr */ : 16
      /* ComponentAttr */;
    } else {
      return kind.trusting ? 22
      /* TrustingDynamicAttr */ : 15
      /* DynamicAttr */;
    }
  }

  function visit(template) {
    var statements = CONTENT.list(template.body);
    var scope = template.scope;
    var block = [statements, scope.symbols, scope.hasEval, scope.upvars];
    return block;
  }
  var defaultId = (() => {
    var req = typeof module === 'object' && typeof module.require === 'function' ? module.require : require;
    if (req) {
      try {
        var crypto = req('crypto');
        var idFn = src => {
          var hash = crypto.createHash('sha1');
          hash.update(src, 'utf8'); // trim to 6 bytes of data (2^48 - 1)

          return hash.digest('base64').substring(0, 8);
        };
        idFn('test');
        return idFn;
      } catch (e) {}
    }
    return function idFn() {
      return null;
    };
  })();
  _exports.defaultId = defaultId;
  var defaultOptions = {
    id: defaultId
  };
  /*
   * Compile a string into a template javascript string.
   *
   * Example usage:
   *     import { precompile } from '@glimmer/compiler';
   *     import { templateFactory } from 'glimmer-runtime';
   *     let templateJs = precompile("Howdy {{name}}");
   *     let factory = templateFactory(new Function("return " + templateJs)());
   *     let template = factory.create(env);
   *
   * @method precompile
   * @param {string} string a Glimmer template string
   * @return {string} a template javascript string
   */

  function precompileJSON(string, options) {
    if (options === void 0) {
      options = defaultOptions;
    }
    var _a, _b;
    var source = new _syntax.Source(string, (_a = options.meta) === null || _a === void 0 ? void 0 : _a.moduleName);
    var [ast, locals] = (0, _syntax.normalize)(source, options);
    var block = normalize$1(source, ast, (_b = options.strictMode) !== null && _b !== void 0 ? _b : false).mapOk(pass2In => {
      return visit(pass2In);
    });
    if (block.isOk) {
      return [block.value, locals];
    } else {
      throw block.reason;
    }
  } // UUID used as a unique placeholder for placing a snippet of JS code into
  // the otherwise JSON stringified value below.

  var SCOPE_PLACEHOLDER = '796d24e6-2450-4fb0-8cdf-b65638b5ef70';
  /*
   * Compile a string into a template javascript string.
   *
   * Example usage:
   *     import { precompile } from '@glimmer/compiler';
   *     import { templateFactory } from 'glimmer-runtime';
   *     let templateJs = precompile("Howdy {{name}}");
   *     let factory = templateFactory(new Function("return " + templateJs)());
   *     let template = factory.create(env);
   *
   * @method precompile
   * @param {string} string a Glimmer template string
   * @return {string} a template javascript string
   */

  function precompile(source, options) {
    if (options === void 0) {
      options = defaultOptions;
    }
    var _a, _b;
    var [block, usedLocals] = precompileJSON(source, options);
    var moduleName = (_a = options.meta) === null || _a === void 0 ? void 0 : _a.moduleName;
    var idFn = options.id || defaultId;
    var blockJSON = JSON.stringify(block);
    var templateJSONObject = {
      id: idFn(JSON.stringify(options.meta) + blockJSON),
      block: blockJSON,
      moduleName: moduleName !== null && moduleName !== void 0 ? moduleName : '(unknown template module)',
      // lying to the type checker here because we're going to
      // replace it just below, after stringification
      scope: SCOPE_PLACEHOLDER,
      isStrictMode: (_b = options.strictMode) !== null && _b !== void 0 ? _b : false
    };
    if (usedLocals.length === 0) {
      delete templateJSONObject.scope;
    } // JSON is javascript

    var stringified = JSON.stringify(templateJSONObject);
    if (usedLocals.length > 0) {
      var scopeFn = "()=>[" + usedLocals.join(',') + "]";
      stringified = stringified.replace("\"" + SCOPE_PLACEHOLDER + "\"", scopeFn);
    }
    return stringified;
  }
  var VariableKind;
  (function (VariableKind) {
    VariableKind["Local"] = "Local";
    VariableKind["Free"] = "Free";
    VariableKind["Arg"] = "Arg";
    VariableKind["Block"] = "Block";
    VariableKind["This"] = "This";
  })(VariableKind || (VariableKind = {}));
  function normalizeStatement(statement) {
    if (Array.isArray(statement)) {
      if (statementIsExpression(statement)) {
        return normalizeAppendExpression(statement);
      } else if (isSugaryArrayStatement(statement)) {
        return normalizeSugaryArrayStatement(statement);
      } else {
        return normalizeVerboseStatement(statement);
      }
    } else if (typeof statement === 'string') {
      return normalizeAppendHead(normalizeDottedPath(statement), false);
    } else {
      throw (0, _util.assertNever)(statement);
    }
  }
  function normalizeAppendHead(head, trusted) {
    if (head.type === "GetPath"
    /* GetPath */) {
      return {
        kind: "AppendPath"
        /* AppendPath */,

        path: head,
        trusted
      };
    } else {
      return {
        kind: "AppendExpr"
        /* AppendExpr */,

        expr: head,
        trusted
      };
    }
  }
  function isSugaryArrayStatement(statement) {
    if (Array.isArray(statement) && typeof statement[0] === 'string') {
      switch (statement[0][0]) {
        case '(':
        case '#':
        case '<':
        case '!':
          return true;
        default:
          return false;
      }
    }
    return false;
  }
  function normalizeSugaryArrayStatement(statement) {
    var name = statement[0];
    switch (name[0]) {
      case '(':
        {
          var params = null;
          var hash = null;
          if (statement.length === 3) {
            params = normalizeParams(statement[1]);
            hash = normalizeHash(statement[2]);
          } else if (statement.length === 2) {
            if (Array.isArray(statement[1])) {
              params = normalizeParams(statement[1]);
            } else {
              hash = normalizeHash(statement[1]);
            }
          }
          return {
            kind: "Call"
            /* Call */,

            head: normalizeCallHead(name),
            params,
            hash,
            trusted: false
          };
        }
      case '#':
        {
          var {
            head: path,
            params: _params,
            hash: _hash,
            blocks,
            blockParams
          } = normalizeBuilderBlockStatement(statement);
          return {
            kind: "Block"
            /* Block */,

            head: path,
            params: _params,
            hash: _hash,
            blocks,
            blockParams
          };
        }
      case '!':
        {
          var _name2 = statement[0].slice(1);
          var {
            params: _params2,
            hash: _hash2,
            blocks: _blocks,
            blockParams: _blockParams
          } = normalizeBuilderBlockStatement(statement);
          return {
            kind: "Keyword"
            /* Keyword */,

            name: _name2,
            params: _params2,
            hash: _hash2,
            blocks: _blocks,
            blockParams: _blockParams
          };
        }
      case '<':
        {
          var attrs = (0, _util.dict)();
          var block = [];
          if (statement.length === 3) {
            attrs = normalizeAttrs(statement[1]);
            block = normalizeBlock(statement[2]);
          } else if (statement.length === 2) {
            if (Array.isArray(statement[1])) {
              block = normalizeBlock(statement[1]);
            } else {
              attrs = normalizeAttrs(statement[1]);
            }
          }
          return {
            kind: "Element"
            /* Element */,

            name: extractElement(name),
            attrs,
            block
          };
        }
      default:
        throw new Error("Unreachable " + JSON.stringify(statement) + " in normalizeSugaryArrayStatement");
    }
  }
  function normalizeVerboseStatement(statement) {
    switch (statement[0]) {
      case 0
      /* Literal */:
        {
          return {
            kind: "Literal"
            /* Literal */,

            value: statement[1]
          };
        }
      case 2
      /* Append */:
        {
          return normalizeAppendExpression(statement[1], statement[2]);
        }
      case 3
      /* Modifier */:
        {
          return {
            kind: "Modifier"
            /* Modifier */,

            params: normalizeParams(statement[1]),
            hash: normalizeHash(statement[2])
          };
        }
      case 4
      /* DynamicComponent */:
        {
          return {
            kind: "DynamicComponent"
            /* DynamicComponent */,

            expr: normalizeExpression(statement[1]),
            hash: normalizeHash(statement[2]),
            block: normalizeBlock(statement[3])
          };
        }
      case 1
      /* Comment */:
        {
          return {
            kind: "Comment"
            /* Comment */,

            value: statement[1]
          };
        }
    }
  }
  function extractBlockHead(name) {
    var result = /^(#|!)(.*)$/.exec(name);
    if (result === null) {
      throw new Error("Unexpected missing # in block head");
    }
    return normalizeDottedPath(result[2]);
  }
  function normalizeCallHead(name) {
    var result = /^\((.*)\)$/.exec(name);
    if (result === null) {
      throw new Error("Unexpected missing () in call head");
    }
    return normalizeDottedPath(result[1]);
  }
  function normalizePath(head, tail) {
    if (tail === void 0) {
      tail = [];
    }
    var pathHead = normalizePathHead(head);
    if ((0, _util.isPresent)(tail)) {
      return {
        type: "GetPath"
        /* GetPath */,

        path: {
          head: pathHead,
          tail
        }
      };
    } else {
      return {
        type: "GetVar"
        /* GetVar */,

        variable: pathHead
      };
    }
  }
  function normalizeDottedPath(whole) {
    var {
      kind,
      name: rest
    } = normalizePathHead(whole);
    var [name, ...tail] = rest.split('.');
    var variable = {
      kind,
      name,
      mode: 'loose'
    };
    if ((0, _util.isPresent)(tail)) {
      return {
        type: "GetPath"
        /* GetPath */,

        path: {
          head: variable,
          tail
        }
      };
    } else {
      return {
        type: "GetVar"
        /* GetVar */,

        variable
      };
    }
  }
  function normalizePathHead(whole) {
    var kind;
    var name;
    if (/^this(\.|$)/.exec(whole)) {
      return {
        kind: VariableKind.This,
        name: whole,
        mode: 'loose'
      };
    }
    switch (whole[0]) {
      case '^':
        kind = VariableKind.Free;
        name = whole.slice(1);
        break;
      case '@':
        kind = VariableKind.Arg;
        name = whole.slice(1);
        break;
      case '&':
        kind = VariableKind.Block;
        name = whole.slice(1);
        break;
      default:
        kind = VariableKind.Local;
        name = whole;
    }
    return {
      kind,
      name,
      mode: 'loose'
    };
  }
  function normalizeBuilderBlockStatement(statement) {
    var head = statement[0];
    var blocks = (0, _util.dict)();
    var params = null;
    var hash = null;
    var blockParams = null;
    if (statement.length === 2) {
      blocks = normalizeBlocks(statement[1]);
    } else if (statement.length === 3) {
      if (Array.isArray(statement[1])) {
        params = normalizeParams(statement[1]);
      } else {
        ({
          hash,
          blockParams
        } = normalizeBlockHash(statement[1]));
      }
      blocks = normalizeBlocks(statement[2]);
    } else if (statement.length === 4) {
      params = normalizeParams(statement[1]);
      ({
        hash,
        blockParams
      } = normalizeBlockHash(statement[2]));
      blocks = normalizeBlocks(statement[3]);
    }
    return {
      head: extractBlockHead(head),
      params,
      hash,
      blockParams,
      blocks
    };
  }
  function normalizeBlockHash(hash) {
    if (hash === null) {
      return {
        hash: null,
        blockParams: null
      };
    }
    var out = null;
    var blockParams = null;
    entries(hash, (key, value) => {
      if (key === 'as') {
        blockParams = Array.isArray(value) ? value : [value];
      } else {
        out = out || (0, _util.dict)();
        out[key] = normalizeExpression(value);
      }
    });
    return {
      hash: out,
      blockParams
    };
  }
  function entries(dict$$1, callback) {
    Object.keys(dict$$1).forEach(key => {
      var value = dict$$1[key];
      callback(key, value);
    });
  }
  function normalizeBlocks(value) {
    if (Array.isArray(value)) {
      return {
        default: normalizeBlock(value)
      };
    } else {
      return mapObject(value, normalizeBlock);
    }
  }
  function normalizeBlock(block) {
    return block.map(s => normalizeStatement(s));
  }
  function normalizeAttrs(attrs) {
    return mapObject(attrs, a => normalizeAttr(a).expr);
  }
  function normalizeAttr(attr) {
    if (attr === 'splat') {
      return {
        expr: "Splat"
        /* Splat */,

        trusted: false
      };
    } else {
      var expr = normalizeExpression(attr);
      return {
        expr,
        trusted: false
      };
    }
  }
  function mapObject(object, callback) {
    var out = (0, _util.dict)();
    Object.keys(object).forEach(k => {
      out[k] = callback(object[k], k);
    });
    return out;
  }
  function extractElement(input) {
    var match = /^<([a-z0-9\-][a-zA-Z0-9\-]*)>$/.exec(input);
    return match ? match[1] : null;
  }
  function normalizeAppendExpression(expression, forceTrusted) {
    if (forceTrusted === void 0) {
      forceTrusted = false;
    }
    if (expression === null || expression === undefined) {
      return {
        expr: {
          type: "Literal"
          /* Literal */,

          value: expression
        },
        kind: "AppendExpr"
        /* AppendExpr */,

        trusted: false
      };
    } else if (Array.isArray(expression)) {
      switch (expression[0]) {
        case 0
        /* Literal */:
          return {
            expr: {
              type: "Literal"
              /* Literal */,

              value: expression[1]
            },
            kind: "AppendExpr"
            /* AppendExpr */,

            trusted: false
          };
        case 5
        /* Get */:
          {
            return normalizeAppendHead(normalizePath(expression[1], expression[2]), forceTrusted);
          }
        case 6
        /* Concat */:
          {
            var expr = {
              type: "Concat"
              /* Concat */,

              params: normalizeParams(expression.slice(1))
            };
            return {
              expr,
              kind: "AppendExpr"
              /* AppendExpr */,

              trusted: forceTrusted
            };
          }
        case 7
        /* HasBlock */:
          return {
            expr: {
              type: "HasBlock"
              /* HasBlock */,

              name: expression[1]
            },
            kind: "AppendExpr"
            /* AppendExpr */,

            trusted: forceTrusted
          };
        case 8
        /* HasBlockParams */:
          return {
            expr: {
              type: "HasBlockParams"
              /* HasBlockParams */,

              name: expression[1]
            },
            kind: "AppendExpr"
            /* AppendExpr */,

            trusted: forceTrusted
          };
        default:
          {
            if (isBuilderCallExpression(expression)) {
              return {
                expr: normalizeCallExpression(expression),
                kind: "AppendExpr"
                /* AppendExpr */,

                trusted: forceTrusted
              };
            } else {
              throw new Error("Unexpected array in expression position (wasn't a tuple expression and " + expression[0] + " isn't wrapped in parens, so it isn't a call): " + JSON.stringify(expression));
            }
          }
        // BuilderCallExpression
      }
    } else if (typeof expression !== 'object') {
      switch (typeof expression) {
        case 'string':
          {
            return normalizeAppendHead(normalizeDottedPath(expression), forceTrusted);
          }
        case 'boolean':
        case 'number':
          return {
            expr: {
              type: "Literal"
              /* Literal */,

              value: expression
            },
            kind: "AppendExpr"
            /* AppendExpr */,

            trusted: true
          };
        default:
          throw (0, _util.assertNever)(expression);
      }
    } else {
      throw (0, _util.assertNever)(expression);
    }
  }
  function normalizeExpression(expression) {
    if (expression === null || expression === undefined) {
      return {
        type: "Literal"
        /* Literal */,

        value: expression
      };
    } else if (Array.isArray(expression)) {
      switch (expression[0]) {
        case 0
        /* Literal */:
          return {
            type: "Literal"
            /* Literal */,

            value: expression[1]
          };
        case 5
        /* Get */:
          {
            return normalizePath(expression[1], expression[2]);
          }
        case 6
        /* Concat */:
          {
            var expr = {
              type: "Concat"
              /* Concat */,

              params: normalizeParams(expression.slice(1))
            };
            return expr;
          }
        case 7
        /* HasBlock */:
          return {
            type: "HasBlock"
            /* HasBlock */,

            name: expression[1]
          };
        case 8
        /* HasBlockParams */:
          return {
            type: "HasBlockParams"
            /* HasBlockParams */,

            name: expression[1]
          };
        default:
          {
            if (isBuilderCallExpression(expression)) {
              return normalizeCallExpression(expression);
            } else {
              throw new Error("Unexpected array in expression position (wasn't a tuple expression and " + expression[0] + " isn't wrapped in parens, so it isn't a call): " + JSON.stringify(expression));
            }
          }
        // BuilderCallExpression
      }
    } else if (typeof expression !== 'object') {
      switch (typeof expression) {
        case 'string':
          {
            return normalizeDottedPath(expression);
          }
        case 'boolean':
        case 'number':
          return {
            type: "Literal"
            /* Literal */,

            value: expression
          };
        default:
          throw (0, _util.assertNever)(expression);
      }
    } else {
      throw (0, _util.assertNever)(expression);
    }
  }
  function statementIsExpression(statement) {
    if (!Array.isArray(statement)) {
      return false;
    }
    var name = statement[0];
    if (typeof name === 'number') {
      switch (name) {
        case 0
        /* Literal */:

        case 5
        /* Get */:

        case 6
        /* Concat */:

        case 7
        /* HasBlock */:

        case 8
        /* HasBlockParams */:
          return true;
        default:
          return false;
      }
    }
    if (name[0] === '(') {
      return true;
    }
    return false;
  }
  function isBuilderCallExpression(value) {
    return typeof value[0] === 'string' && value[0][0] === '(';
  }
  function normalizeParams(input) {
    return input.map(normalizeExpression);
  }
  function normalizeHash(input) {
    if (input === null) return null;
    return mapObject(input, normalizeExpression);
  }
  function normalizeCallExpression(expr) {
    switch (expr.length) {
      case 1:
        return {
          type: "Call"
          /* Call */,

          head: normalizeCallHead(expr[0]),
          params: null,
          hash: null
        };
      case 2:
        {
          if (Array.isArray(expr[1])) {
            return {
              type: "Call"
              /* Call */,

              head: normalizeCallHead(expr[0]),
              params: normalizeParams(expr[1]),
              hash: null
            };
          } else {
            return {
              type: "Call"
              /* Call */,

              head: normalizeCallHead(expr[0]),
              params: null,
              hash: normalizeHash(expr[1])
            };
          }
        }
      case 3:
        return {
          type: "Call"
          /* Call */,

          head: normalizeCallHead(expr[0]),
          params: normalizeParams(expr[1]),
          hash: normalizeHash(expr[2])
        };
    }
  }
  class ProgramSymbols {
    constructor() {
      this._freeVariables = [];
      this._symbols = ['this'];
      this.top = this;
    }
    toSymbols() {
      return this._symbols.slice(1);
    }
    toUpvars() {
      return this._freeVariables;
    }
    freeVar(name) {
      return addString(this._freeVariables, name);
    }
    block(name) {
      return this.symbol(name);
    }
    arg(name) {
      return addString(this._symbols, name);
    }
    local(name) {
      throw new Error("No local " + name + " was found. Maybe you meant ^" + name + " for upvar, or !" + name + " for keyword?");
    }
    this() {
      return 0;
    }
    hasLocal(_name) {
      return false;
    } // any symbol

    symbol(name) {
      return addString(this._symbols, name);
    }
    child(locals) {
      return new LocalSymbols(this, locals);
    }
  }
  _exports.ProgramSymbols = ProgramSymbols;
  class LocalSymbols {
    constructor(parent, locals) {
      this.parent = parent;
      this.locals = (0, _util.dict)();
      for (var local of locals) {
        this.locals[local] = parent.top.symbol(local);
      }
    }
    get paramSymbols() {
      return (0, _util.values)(this.locals);
    }
    get top() {
      return this.parent.top;
    }
    freeVar(name) {
      return this.parent.freeVar(name);
    }
    arg(name) {
      return this.parent.arg(name);
    }
    block(name) {
      return this.parent.block(name);
    }
    local(name) {
      if (name in this.locals) {
        return this.locals[name];
      } else {
        return this.parent.local(name);
      }
    }
    this() {
      return this.parent.this();
    }
    hasLocal(name) {
      if (name in this.locals) {
        return true;
      } else {
        return this.parent.hasLocal(name);
      }
    }
    child(locals) {
      return new LocalSymbols(this, locals);
    }
  }
  function addString(array, item) {
    var index = array.indexOf(item);
    if (index === -1) {
      index = array.length;
      array.push(item);
      return index;
    } else {
      return index;
    }
  }
  function unimpl(message) {
    return new Error("unimplemented " + message);
  }
  function buildStatements(statements, symbols) {
    var out = [];
    statements.forEach(s => out.push(...buildStatement(normalizeStatement(s), symbols)));
    return out;
  }
  function buildNormalizedStatements(statements, symbols) {
    var out = [];
    statements.forEach(s => out.push(...buildStatement(s, symbols)));
    return out;
  }
  function buildStatement(normalized, symbols) {
    if (symbols === void 0) {
      symbols = new ProgramSymbols();
    }
    switch (normalized.kind) {
      case "AppendPath"
      /* AppendPath */:
        {
          return [[normalized.trusted ? 2
          /* TrustingAppend */ : 1
          /* Append */, buildGetPath(normalized.path, symbols)]];
        }
      case "AppendExpr"
      /* AppendExpr */:
        {
          return [[normalized.trusted ? 2
          /* TrustingAppend */ : 1
          /* Append */, buildExpression(normalized.expr, normalized.trusted ? 'TrustedAppend' : 'Append', symbols)]];
        }
      case "Call"
      /* Call */:
        {
          var {
            head: path,
            params,
            hash,
            trusted
          } = normalized;
          var builtParams = params ? buildParams(params, symbols) : null;
          var builtHash = hash ? buildHash(hash, symbols) : null;
          var builtExpr = buildCallHead(path, trusted ? 3
          /* AmbiguousInvoke */ : 2
          /* AmbiguousAppendInvoke */, symbols);
          return [[trusted ? 2
          /* TrustingAppend */ : 1
          /* Append */, [28
          /* Call */, builtExpr, builtParams, builtHash]]];
        }
      case "Literal"
      /* Literal */:
        {
          return [[1
          /* Append */, normalized.value]];
        }
      case "Comment"
      /* Comment */:
        {
          return [[3
          /* Comment */, normalized.value]];
        }
      case "Block"
      /* Block */:
        {
          var blocks = buildBlocks(normalized.blocks, normalized.blockParams, symbols);
          var _hash3 = buildHash(normalized.hash, symbols);
          var _params3 = buildParams(normalized.params, symbols);
          var _path = buildCallHead(normalized.head, 7
          /* ResolveAsComponentHead */, symbols);
          return [[6
          /* Block */, _path, _params3, _hash3, blocks]];
        }
      case "Keyword"
      /* Keyword */:
        {
          return [buildKeyword(normalized, symbols)];
        }
      case "Element"
      /* Element */:
        return buildElement(normalized, symbols);
      case "Modifier"
      /* Modifier */:
        throw unimpl('modifier');
      case "DynamicComponent"
      /* DynamicComponent */:
        throw unimpl('dynamic component');
      default:
        throw (0, _util.assertNever)(normalized);
    }
  }
  function s(arr) {
    for (var _len2 = arguments.length, interpolated = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      interpolated[_key2 - 1] = arguments[_key2];
    }
    var result = arr.reduce((result, string, i) => result + ("" + string + (interpolated[i] ? String(interpolated[i]) : '')), '');
    return [0
    /* Literal */, result];
  }
  function c(arr) {
    for (var _len3 = arguments.length, interpolated = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      interpolated[_key3 - 1] = arguments[_key3];
    }
    var result = arr.reduce((result, string, i) => result + ("" + string + (interpolated[i] ? String(interpolated[i]) : '')), '');
    return [1
    /* Comment */, result];
  }
  function unicode(charCode) {
    return String.fromCharCode(parseInt(charCode, 16));
  }
  var NEWLINE = '\n';
  _exports.NEWLINE = NEWLINE;
  function buildKeyword(normalized, symbols) {
    var {
      name
    } = normalized;
    var params = buildParams(normalized.params, symbols);
    var childSymbols = symbols.child(normalized.blockParams || []);
    var block = buildBlock(normalized.blocks.default, childSymbols, childSymbols.paramSymbols);
    var inverse = normalized.blocks.else ? buildBlock(normalized.blocks.else, symbols, []) : null;
    switch (name) {
      case 'with':
        return [43
        /* With */, params[0], block, inverse];
      case 'if':
        return [41
        /* If */, params[0], block, inverse];
      case 'each':
        var keyExpr = normalized.hash ? normalized.hash['key'] : null;
        var key = keyExpr ? buildExpression(keyExpr, 'Strict', symbols) : null;
        return [42
        /* Each */, params[0], key, block, inverse];
      default:
        throw new Error('unimplemented keyword');
    }
  }
  function buildElement(_ref99, symbols) {
    var {
      name,
      attrs,
      block
    } = _ref99;
    var out = [hasSplat(attrs) ? [11
    /* OpenElementWithSplat */, name] : [10
    /* OpenElement */, name]];
    if (attrs) {
      var {
        params,
        args
      } = buildElementParams(attrs, symbols);
      out.push(...params);
    }
    out.push([12
    /* FlushElement */]);

    if (Array.isArray(block)) {
      block.forEach(s => out.push(...buildStatement(s, symbols)));
    } else if (block === null) {// do nothing
    } else {
      throw (0, _util.assertNever)(block);
    }
    out.push([13
    /* CloseElement */]);

    return out;
  }
  function hasSplat(attrs) {
    if (attrs === null) return false;
    return Object.keys(attrs).some(a => attrs[a] === "Splat"
    /* Splat */);
  }

  function buildElementParams(attrs, symbols) {
    var params = [];
    var keys = [];
    var values$$1 = [];
    Object.keys(attrs).forEach(key => {
      var value = attrs[key];
      if (value === "Splat"
      /* Splat */) {
        params.push([17
        /* AttrSplat */, symbols.block('&attrs')]);
      } else if (key[0] === '@') {
        keys.push(key);
        values$$1.push(buildExpression(value, 'Strict', symbols));
      } else {
        params.push(...buildAttributeValue(key, value,
        // TODO: extract namespace from key
        extractNamespace(key), symbols));
      }
    });
    return {
      params,
      args: (0, _util.isPresent)(keys) && (0, _util.isPresent)(values$$1) ? [keys, values$$1] : null
    };
  }
  function extractNamespace(name) {
    if (name === 'xmlns') {
      return "http://www.w3.org/2000/xmlns/"
      /* XMLNS */;
    }

    var match = /^([^:]*):([^:]*)$/.exec(name);
    if (match === null) {
      return null;
    }
    var namespace = match[1];
    switch (namespace) {
      case 'xlink':
        return "http://www.w3.org/1999/xlink"
        /* XLink */;

      case 'xml':
        return "http://www.w3.org/XML/1998/namespace"
        /* XML */;

      case 'xmlns':
        return "http://www.w3.org/2000/xmlns/"
        /* XMLNS */;
    }

    return null;
  }
  function buildAttributeValue(name, value, namespace, symbols) {
    switch (value.type) {
      case "Literal"
      /* Literal */:
        {
          var val = value.value;
          if (val === false) {
            return [];
          } else if (val === true) {
            return [[14
            /* StaticAttr */, name, '', namespace !== null && namespace !== void 0 ? namespace : undefined]];
          } else if (typeof val === 'string') {
            return [[14
            /* StaticAttr */, name, val, namespace !== null && namespace !== void 0 ? namespace : undefined]];
          } else {
            throw new Error("Unexpected/unimplemented literal attribute " + JSON.stringify(val));
          }
        }
      default:
        return [[15
        /* DynamicAttr */, name, buildExpression(value, 'AttrValue', symbols), namespace !== null && namespace !== void 0 ? namespace : undefined]];
    }
  }
  function varContext(context, bare) {
    switch (context) {
      case 'Append':
        return bare ? 'AppendBare' : 'AppendInvoke';
      case 'TrustedAppend':
        return bare ? 'TrustedAppendBare' : 'TrustedAppendInvoke';
      case 'AttrValue':
        return bare ? 'AttrValueBare' : 'AttrValueInvoke';
      default:
        return context;
    }
  }
  function buildExpression(expr, context, symbols) {
    switch (expr.type) {
      case "GetPath"
      /* GetPath */:
        {
          return buildGetPath(expr, symbols);
        }
      case "GetVar"
      /* GetVar */:
        {
          return buildVar(expr.variable, varContext(context, true), symbols);
        }
      case "Concat"
      /* Concat */:
        {
          return [29
          /* Concat */, buildConcat(expr.params, symbols)];
        }
      case "Call"
      /* Call */:
        {
          var builtParams = buildParams(expr.params, symbols);
          var builtHash = buildHash(expr.hash, symbols);
          var builtExpr = buildCallHead(expr.head, context === 'Strict' ? 'SubExpression' : varContext(context, false), symbols);
          return [28
          /* Call */, builtExpr, builtParams, builtHash];
        }
      case "HasBlock"
      /* HasBlock */:
        {
          return [48
          /* HasBlock */, buildVar({
            kind: VariableKind.Block,
            name: expr.name,
            mode: 'loose'
          }, 0
          /* Strict */, symbols)];
        }
      case "HasBlockParams"
      /* HasBlockParams */:
        {
          return [49
          /* HasBlockParams */, buildVar({
            kind: VariableKind.Block,
            name: expr.name,
            mode: 'loose'
          }, 0
          /* Strict */, symbols)];
        }
      case "Literal"
      /* Literal */:
        {
          if (expr.value === undefined) {
            return [27
            /* Undefined */];
          } else {
            return expr.value;
          }
        }
      default:
        (0, _util.assertNever)(expr);
    }
  }
  function buildCallHead(callHead, context, symbols) {
    if (callHead.type === "GetVar"
    /* GetVar */) {
      return buildVar(callHead.variable, context, symbols);
    } else {
      return buildGetPath(callHead, symbols);
    }
  }
  function buildGetPath(head, symbols) {
    return buildVar(head.path.head, 0
    /* Strict */, symbols, head.path.tail);
  }
  function buildVar(head, context, symbols, path) {
    var op = 30
    /* GetSymbol */;

    var sym;
    switch (head.kind) {
      case VariableKind.Free:
        if (context === 'Strict') {
          op = 31
          /* GetStrictFree */;
        } else if (context === 'AppendBare') {
          op = 34
          /* GetFreeAsComponentOrHelperHeadOrThisFallback */;
        } else if (context === 'AppendInvoke') {
          op = 35
          /* GetFreeAsComponentOrHelperHead */;
        } else if (context === 'TrustedAppendBare') {
          op = 36
          /* GetFreeAsHelperHeadOrThisFallback */;
        } else if (context === 'TrustedAppendInvoke') {
          op = 37
          /* GetFreeAsHelperHead */;
        } else if (context === 'AttrValueBare') {
          op = 36
          /* GetFreeAsHelperHeadOrThisFallback */;
        } else if (context === 'AttrValueInvoke') {
          op = 37
          /* GetFreeAsHelperHead */;
        } else if (context === 'SubExpression') {
          op = 37
          /* GetFreeAsHelperHead */;
        } else {
          op = expressionContextOp(context);
        }
        sym = symbols.freeVar(head.name);
        break;
      default:
        op = 30
        /* GetSymbol */;

        sym = getSymbolForVar(head.kind, symbols, head.name);
    }
    if (path === undefined || path.length === 0) {
      return [op, sym];
    } else {
      return [op, sym, path];
    }
  }
  function getSymbolForVar(kind, symbols, name) {
    switch (kind) {
      case VariableKind.Arg:
        return symbols.arg(name);
      case VariableKind.Block:
        return symbols.block(name);
      case VariableKind.Local:
        return symbols.local(name);
      case VariableKind.This:
        return symbols.this();
      default:
        return (0, _util.exhausted)(kind);
    }
  }
  function expressionContextOp(context) {
    switch (context) {
      case 0
      /* Strict */:
        return 31
        /* GetStrictFree */;

      case 1
      /* AmbiguousAppend */:
        return 34
        /* GetFreeAsComponentOrHelperHeadOrThisFallback */;

      case 2
      /* AmbiguousAppendInvoke */:
        return 35
        /* GetFreeAsComponentOrHelperHead */;

      case 3
      /* AmbiguousInvoke */:
        return 36
        /* GetFreeAsHelperHeadOrThisFallback */;

      case 5
      /* ResolveAsCallHead */:
        return 37
        /* GetFreeAsHelperHead */;

      case 6
      /* ResolveAsModifierHead */:
        return 38
        /* GetFreeAsModifierHead */;

      case 7
      /* ResolveAsComponentHead */:
        return 39
        /* GetFreeAsComponentHead */;

      default:
        return (0, _util.exhausted)(context);
    }
  }
  function buildParams(exprs, symbols) {
    if (exprs === null || !(0, _util.isPresent)(exprs)) return null;
    return exprs.map(e => buildExpression(e, 'Strict', symbols));
  }
  function buildConcat(exprs, symbols) {
    return exprs.map(e => buildExpression(e, 'AttrValue', symbols));
  }
  function buildHash(exprs, symbols) {
    if (exprs === null) return null;
    var out = [[], []];
    Object.keys(exprs).forEach(key => {
      out[0].push(key);
      out[1].push(buildExpression(exprs[key], 'Strict', symbols));
    });
    return out;
  }
  function buildBlocks(blocks, blockParams, parent) {
    var keys = [];
    var values$$1 = [];
    Object.keys(blocks).forEach(name => {
      keys.push(name);
      if (name === 'default') {
        var symbols = parent.child(blockParams || []);
        values$$1.push(buildBlock(blocks[name], symbols, symbols.paramSymbols));
      } else {
        values$$1.push(buildBlock(blocks[name], parent, []));
      }
    });
    return [keys, values$$1];
  }
  function buildBlock(block, symbols, locals) {
    if (locals === void 0) {
      locals = [];
    }
    return [buildNormalizedStatements(block, symbols), locals];
  }
});