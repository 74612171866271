define("ember-template-compiler/lib/plugins/transform-in-element", ["exports", "@ember/debug", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformInElement;
  /**
   @module ember
  */
  /**
    A Glimmer2 AST transformation that handles the public `{{in-element}}` as per RFC287.
  
    Issues a build time assertion for:
  
    ```handlebars
    {{#in-element someElement insertBefore="some-none-null-value"}}
      {{modal-display text=text}}
    {{/in-element}}
    ```
  
    @private
    @class TransformInElement
  */
  function transformInElement(env) {
    var {
      builders: b
    } = env.syntax;
    return {
      name: 'transform-in-element',
      visitor: {
        BlockStatement(node) {
          if (!(0, _utils.isPath)(node.path)) return;
          if (node.path.original === 'in-element') {
            var originalValue = node.params[0];
            if (originalValue && !env.isProduction) {
              var subExpr = b.sexpr('-in-el-null', [originalValue]);
              node.params.shift();
              node.params.unshift(subExpr);
            }
            node.hash.pairs.forEach(pair => {
              if (pair.key === 'insertBefore') {
                (true && !(pair.value.type === 'NullLiteral' || pair.value.type === 'UndefinedLiteral') && (0, _debug.assert)("Can only pass null to insertBefore in in-element, received: " + JSON.stringify(pair.value), pair.value.type === 'NullLiteral' || pair.value.type === 'UndefinedLiteral'));
              }
            });
          }
        }
      }
    };
  }
});