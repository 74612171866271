define("ember-toggle/components/x-toggle-switch", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/runloop", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _object, _component2, _runloop, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class="x-toggle-container
      {{@size}}
      {{if @disabled 'x-toggle-container-disabled'}}
      {{if @value 'x-toggle-container-checked'}}
    "
    ...attributes
    {{did-pan
      onPanStart=this.handlePan
      onPan=this.handlePan
      pointerTypes=(array 'touch' 'mouse')
    }}
    {{will-destroy this.removeListener}}
  >
    <input
      class='x-toggle'
      type='checkbox'
      checked={{@toggled}}
      disabled={{@disabled}}
      id={{@forId}}
      name={{@name}}
      {{on 'change' this.onChange}}
    />
  
    <label for={{this.effectiveForId}}>
      <div
        id="x-toggle-visual-{{@forId}}"
        role='checkbox'
        class="x-toggle-btn
          {{this.themeClass}}
          {{@variant}}
          {{@size}}
          {{if @disabled ' x-toggle-disabled'}}
        "
        aria-owns={{@forId}}
        aria-checked={{@toggled}}
        data-tg-on={{@onLabel}}
        data-tg-off={{@offLabel}}
      >
      </div>
    </label>
  </span>
  */
  {
    "id": "78IpSZzX",
    "block": "[[[11,1],[16,0,[29,[\"x-toggle-container\\n    \",[30,1],\"\\n    \",[52,[30,2],\"x-toggle-container-disabled\"],\"\\n    \",[52,[30,3],\"x-toggle-container-checked\"],\"\\n  \"]]],[17,4],[4,[38,1],null,[[\"onPanStart\",\"onPan\",\"pointerTypes\"],[[30,0,[\"handlePan\"]],[30,0,[\"handlePan\"]],[28,[37,2],[\"touch\",\"mouse\"],null]]]],[4,[38,3],[[30,0,[\"removeListener\"]]],null],[12],[1,\"\\n  \"],[11,\"input\"],[24,0,\"x-toggle\"],[16,\"checked\",[30,5]],[16,\"disabled\",[30,2]],[16,1,[30,6]],[16,3,[30,7]],[24,4,\"checkbox\"],[4,[38,4],[\"change\",[30,0,[\"onChange\"]]],null],[12],[13],[1,\"\\n\\n  \"],[10,\"label\"],[15,\"for\",[30,0,[\"effectiveForId\"]]],[12],[1,\"\\n    \"],[10,0],[15,1,[29,[\"x-toggle-visual-\",[30,6]]]],[14,\"role\",\"checkbox\"],[15,0,[29,[\"x-toggle-btn\\n        \",[30,0,[\"themeClass\"]],\"\\n        \",[30,8],\"\\n        \",[30,1],\"\\n        \",[52,[30,2],\" x-toggle-disabled\"],\"\\n      \"]]],[15,\"aria-owns\",[30,6]],[15,\"aria-checked\",[30,5]],[15,\"data-tg-on\",[30,9]],[15,\"data-tg-off\",[30,10]],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@size\",\"@disabled\",\"@value\",\"&attrs\",\"@toggled\",\"@forId\",\"@name\",\"@variant\",\"@onLabel\",\"@offLabel\"],false,[\"if\",\"did-pan\",\"array\",\"will-destroy\",\"on\"]]",
    "moduleName": "ember-toggle/components/x-toggle-switch.hbs",
    "isStrictMode": false
  });
  let XToggleSwitch = _exports.default = (_class = class XToggleSwitch extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "labelDisabled", _descriptor, this);
    }
    get effectiveForId() {
      return this.labelDisabled ? null : this.args.forId;
    }
    get themeClass() {
      let theme = this.args.theme || 'default';
      return `x-toggle-${theme}`;
    }
    handlePan(touchData) {
      if (this.args.disabled) {
        return;
      }
      const isToggled = touchData.current.distanceX > 0;
      this.args.sendToggle(isToggled);
      this._disableLabelUntilMouseUp();
    }
    onChange(e) {
      if (this.args.disabled) {
        return;
      }
      this.args.sendToggle(e.target.checked);
    }
    removeListener() {
      this._removeListener();
    }

    /*
      When you pan with a mouse and release the mouse button over the <label>
      element, a click event happens and returns the toggle to its initial
      state. :(
       To prevent this, we need to make the label non-functional until after the
      mouse button is released.
     */
    _disableLabelUntilMouseUp() {
      if (this.labelDisabled) {
        return;
      }
      const _listener = () => {
        (0, _runloop.next)(() => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }
          this._removeListener();
          this.labelDisabled = false;
        });
      };
      this.labelDisabled = true;
      this._listener = _listener;
      document.addEventListener('mouseup', _listener);
    }
    _removeListener() {
      const _listener = this._listener;
      if (_listener) {
        document.removeEventListener('mouseup', _listener);
        this._listener = null;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "labelDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handlePan", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeListener", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeListener"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, XToggleSwitch);
});