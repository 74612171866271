define("@aboveproperty/dynamic-template/utils/register-component-for-dynamic-template", ["exports", "@glimmer/manager", "@ember/template-compilation"], function (_exports, _manager, _templateCompilation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dynamicTemplate = (owner, originalFactory, Component, namespace, className) => {
    const key = namespace ? `${namespace}.${className}` : className;
    const customTemplateConfig = owner.lookup('config:dynamic-component-template')?.[key];
    if (customTemplateConfig?.template) {
      if (customTemplateConfig?.css) {
        return (0, _templateCompilation.compileTemplate)(`<style>${customTemplateConfig.css}</style>` + customTemplateConfig.template);
      } else {
        return (0, _templateCompilation.compileTemplate)(customTemplateConfig.template);
      }
    }
    if (customTemplateConfig?.css) {
      const cssFactory = (0, _templateCompilation.compileTemplate)(`<style>${customTemplateConfig.css}</style>`);
      return owner => {
        // compile the css as a template and insert it before the blocks compiled template
        const parsedCss = cssFactory(owner);
        const parsedTemplate = originalFactory(owner);
        parsedTemplate.parsedLayout.block[0] = [...parsedCss.parsedLayout.block[0], ...parsedTemplate.parsedLayout.block[0]];
        return parsedTemplate;
      };
    }
    return originalFactory;
  };
  const dynamicTemplateFactory = (originalFactory, ComponentClass, namespace, className) => {
    return owner => {
      return dynamicTemplate(owner, originalFactory, ComponentClass, namespace, className)(owner);
    };
  };
  const registerComponentForDynamicTemplate = (originalFactory, ComponentClass, namespace, className) => {
    return (0, _manager.setComponentTemplate)(dynamicTemplateFactory(originalFactory, ComponentClass, namespace, className), ComponentClass);
  };
  var _default = _exports.default = registerComponentForDynamicTemplate;
});