define("ember-template-compiler/lib/system/compile-options", ["exports", "@ember/debug", "ember-template-compiler/lib/plugins/index", "ember-template-compiler/lib/system/dasherize-component-name"], function (_exports, _debug, _index, _dasherizeComponentName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildCompileOptions = buildCompileOptions;
  _exports.default = compileOptions;
  _exports.transformsFor = transformsFor;
  var USER_PLUGINS = [];
  function malformedComponentLookup(string) {
    return string.indexOf('::') === -1 && string.indexOf(':') > -1;
  }
  function buildCompileOptions(_options) {
    var moduleName = _options.moduleName;
    var options = Object.assign({
      meta: {},
      isProduction: false,
      plugins: {
        ast: []
      }
    }, _options, {
      moduleName,
      customizeComponentName(tagname) {
        (true && !(!malformedComponentLookup(tagname)) && (0, _debug.assert)("You tried to invoke a component named <" + tagname + " /> in \"" + (moduleName !== null && moduleName !== void 0 ? moduleName : '[NO MODULE]') + "\", but that is not a valid name for a component. Did you mean to use the \"::\" syntax for nested components?", !malformedComponentLookup(tagname)));
        return _dasherizeComponentName.default.get(tagname);
      }
    });
    if ('locals' in options && !options.locals) {
      // Glimmer's precompile options declare `locals` like:
      //    locals?: string[]
      // but many in-use versions of babel-plugin-htmlbars-inline-precompile will
      // set locals to `null`. This used to work but only because glimmer was
      // ignoring locals for non-strict templates, and now it supports that case.
      delete options.locals;
    }
    // move `moduleName` into `meta` property
    if (options.moduleName) {
      var meta = options.meta;
      (true && !(meta) && (0, _debug.assert)('has meta', meta)); // We just set it
      meta.moduleName = options.moduleName;
    }
    return options;
  }
  function transformsFor(options) {
    return options.strictMode ? _index.STRICT_MODE_TRANSFORMS : _index.RESOLUTION_MODE_TRANSFORMS;
  }
  function compileOptions(_options) {
    if (_options === void 0) {
      _options = {};
    }
    var options = buildCompileOptions(_options);
    var builtInPlugins = transformsFor(options);
    if (!_options.plugins) {
      options.plugins = {
        ast: [...USER_PLUGINS, ...builtInPlugins]
      };
    } else {
      var potententialPugins = [...USER_PLUGINS, ...builtInPlugins];
      (true && !(options.plugins) && (0, _debug.assert)('expected plugins', options.plugins));
      var pluginsToAdd = potententialPugins.filter(plugin => {
        (true && !(options.plugins) && (0, _debug.assert)('expected plugins', options.plugins));
        return options.plugins.ast.indexOf(plugin) === -1;
      });
      options.plugins.ast = options.plugins.ast.concat(pluginsToAdd);
    }
    return options;
  }
});