define("ember-template-compiler/lib/system/calculate-location-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = calculateLocationDisplay;
  function calculateLocationDisplay(moduleName, loc) {
    var moduleInfo = '';
    if (moduleName) {
      moduleInfo += "'" + moduleName + "' ";
    }
    if (loc) {
      var {
        column,
        line
      } = loc.start || {
        line: undefined,
        column: undefined
      };
      if (line !== undefined && column !== undefined) {
        if (moduleName) {
          // only prepend @ if the moduleName was present
          moduleInfo += '@ ';
        }
        moduleInfo += "L" + line + ":C" + column;
      }
    }
    if (moduleInfo) {
      moduleInfo = "(" + moduleInfo + ") ";
    }
    return moduleInfo;
  }
});